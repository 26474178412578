import React, { useState, useEffect, useRef } from "react";
import Script from "next/script";
import Slider from "react-slick";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Link from "next/link";
import { FaRegWindowClose, FaTrashAlt } from "react-icons/fa";
import config from "../../../config";
import {
  getCartDataFromServer,
  loggedInUserCartId,
  removeItemFromCart,
} from "../../../api.services.js/cart";
import swal from "sweetalert";
import { useRouter } from "next/router";
import { getIntouch } from "../../../api.services.js/others";
import cookie from "js-cookie";
import axios from "axios";
import VisualSearch2 from "../visualSearch2";
// var jsdom = require('jsdom');
// $ = require('jquery')(new jsdom.JSDOM().window);
const Navbar = () => {
  const router = useRouter("");
  const [showSearchBox, setShowSearchBox] = useState(false);
  const [searchHits, setSearchHits] = useState([]);
  const [searchFooterResult, setSearchFooterResult] = useState();
  const [searchText, setSearchText] = useState("");
  const [searchTextPlaceholderIndex, setsearchTextPlaceholderIndex] =
    useState(0);
  const [searchTextArr, setSearchTextArr] = useState([
    "bathroom tiles",
    "floor tiles",
    "wall tiles",
    "2x2 tiles",
    "kitchen tiles",
  ]);
  const [ModifiedPlaceHolder, setModifiedPlaceHolder] = useState([]);
  const [langModal, setLangModal] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [cartData, setCartData] = useState(null);
  const [navOpen, setNavOpen] = useState(false);
  const searchBoxRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);
  const myComponentRef = useRef(null);
  const [isClassToggled, setIsClassToggled] = useState(Array(13).fill(false));
  const [isTitleHeadToggled, setIsTitleHeadToggled] = useState(
    Array(116).fill(false)
  );
  const [getInTouch, setGetInTouch] = useState(false);
  const [touch, setTouch] = useState({
    name: "",
    number: "",
    pincode: cookie.get("pincode"),
    error: "",
    loading: false,
  });
  const [keywordSuggestions, setkeywordSuggestions] = useState(false);
  function convertPricePerSqmToSqft(pricePerSqm) {
    const conversionFactor = 10.7639;
    return (pricePerSqm / conversionFactor).toFixed(0);
  }
  const getfocusfeeds = async () => {
    if (!searchText) {
      let resp = await axios.get(
        "https://search.unbxd.io/5a4b9251b67027ff94d260e4ac6ac09e/ss-unbxd-aapac-prod-orientbell53741713874410/autosuggest?q=cool&keywordSuggestions.count=6&topQueries.count=0&popularProducts.count=6&inFields.count=3&popularProducts.fields=price%2CproductUrl%2CimageUrl%2Ctitle%2CtileSize&promotedSuggestion.count=0&asd=asd"
      );
      if (resp.status == 200) {
        setkeywordSuggestions(resp.data.response.products);
      }
    }
  };
  const getfeeds = async (e) => {
    setSearchText(e.target.value);
    let resp = await axios.get(
      `https://search.unbxd.io/5a4b9251b67027ff94d260e4ac6ac09e/ss-unbxd-aapac-prod-orientbell53741713874410/autosuggest?q=${e.target.value}&keywordSuggestions.count=6&topQueries.count=0&popularProducts.count=6&inFields.count=3&popularProducts.fields=price%2CproductUrl%2CimageUrl%2Ctitle%2CtileSize&promotedSuggestion.count=0&asd=asd`
    );
    if (resp.status == 200) {
      setkeywordSuggestions(resp.data.response.products);
      console.log(resp.data.response.products);
    }
  };
  const toggleClassInMenu = (index) => {
    const updatedStates = [...isClassToggled];
    updatedStates[index] = !updatedStates[index];
    setIsClassToggled(updatedStates);
  };
  const toggleClassInMenu2 = (index) => {
    const updatedStates = [];
    updatedStates[index] = !updatedStates[index];
    setIsClassToggled(updatedStates);
  };
  const toggleTitleHead = (index) => {
    const updatedStates = [...isTitleHeadToggled];
    updatedStates[index] = !updatedStates[index];
    setIsTitleHeadToggled(updatedStates);
  };

  const startListening = () => {
    try {
      const recognition = new window.webkitSpeechRecognition();
      recognition.start();
      recognition.onresult = (event) => {
        const transcript = event.results[0][0].transcript;
        handleSearch2(transcript);
      };
    } catch (error) {
      console.log(error);
    }
  };
  const submitGetInTouch = async () => {
    const namee = touch.name;
    const no = touch.number;
    const pin = touch.pincode;

    if (!namee || !no || !pin) {
      setTouch({
        ...touch,
        error: "All fields are required",
      });
    } else if (String(pin).length != 6) {
      setTouch({
        ...touch,
        error: "Invalid Pincode",
      });
    } else {
      setTouch({
        ...touch,
        error: "",
        loading: true,
      });
      let response = await getIntouch({
        name: namee,
        phone: no,
        pincode: pin,
      });
      if (response?.message) {
        localStorage.setItem("leadfilled", true);
        swal(response?.message);
        setTouch({
          ...touch,
          error: "",
          loading: false,
        });
      } else {
        swal(response.data.get_in_touch.msg);
        setGetInTouch(false);
        setTouch({
          name: "",
          number: "",
          error: "",
          loading: false,
        });
      }
    }
  };
  const toggleClass = () => {
    setIsToggled(!isToggled);
  };
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleClickOutside = (event) => {
    if (
      myComponentRef.current &&
      !myComponentRef.current.contains(event.target)
    ) {
      setIsToggled(false);
    }
    if (searchBoxRef.current) {
      setShowSearchBox(false);
    }
  };

  const handleScroll = () => {
    // setIsToggled(false);
  };

  const getCartData = async () => {
    if (localStorage.getItem("userToken") && !localStorage.getItem("cartId")) {
      let cartId = await loggedInUserCartId({
        token: localStorage.getItem("userToken"),
      });
      if (
        cartId.message &&
        (cartId.message.includes("The request is allowed for logged in") ||
          cartId.message.includes("The current user cannot perform operations"))
      ) {
        localStorage.removeItem("userToken");
        return;
      }
      localStorage.setItem("cartId", cartId.data.customerCart.id);
      let loggedInUserCartData = await getCartDataFromServer({
        cartId: cartId.data.customerCart.id,
        token: localStorage.getItem("userToken"),
      });
      if (
        loggedInUserCartData.message &&
        (loggedInUserCartData.message.includes(
          "The request is allowed for logged in"
        ) ||
          loggedInUserCartData.message.includes(
            "The current user cannot perform operations"
          ))
      ) {
        localStorage.removeItem("userToken");
        localStorage.removeItem("cartId");
        return;
      }
      setCartData(loggedInUserCartData.data.cart);
      localStorage.setItem("CartData", loggedInUserCartData.data.cart);
    } else if (
      localStorage.getItem("userToken") &&
      localStorage.getItem("cartId")
    ) {
      let loggedInUserCartData = await getCartDataFromServer({
        cartId: localStorage.getItem("cartId"),
        token: localStorage.getItem("userToken"),
      });
      if (
        loggedInUserCartData.message &&
        (loggedInUserCartData.message.includes(
          "The request is allowed for logged in"
        ) ||
          loggedInUserCartData.message.includes(
            "The current user cannot perform operations"
          ))
      ) {
        localStorage.removeItem("userToken");
        localStorage.removeItem("cartId");
        return;
      }
      setCartData(loggedInUserCartData.data.cart);
      localStorage.setItem("CartData", loggedInUserCartData.data.cart);
    } else if (localStorage.getItem("cartId")) {
      let guestCart = await getCartDataFromServer({
        cartId: localStorage.getItem("cartId"),
      });
      if (
        guestCart.message &&
        guestCart.message.includes("The current user cannot perform operations")
      ) {
        localStorage.removeItem("cartId");
        return;
      }
      if (
        guestCart.message &&
        guestCart.message.includes("The request is allowed for logged in")
      ) {
        localStorage.removeItem("cartId");
        localStorage.removeItem("userToken");
        return;
      }
      setCartData(guestCart.data?.cart);
      localStorage.setItem("CartData", guestCart.data?.cart);
    }
  };
  const removeFromCart = async (e) => {
    // setCartData(null);
    let obj = {
      cartId: localStorage.getItem("cartId"),
      cartItemId: e.id,
    };
    if (localStorage.getItem("userToken")) {
      obj = { ...obj, token: localStorage.getItem("userToken") };
    }
    let response = await removeItemFromCart(obj);
    if (response?.message) {
      localStorage.setItem("leadfilled", true);
      swal(response?.message);
    } else {
      setCartData(response.data.removeItemFromCart.cart);
      localStorage.setItem("CartData", response.data.removeItemFromCart.cart);
      swal(`${e.product.name} removed from your cart`).then((e) => {
        setTimeout(() => {
          setIsToggled(true);
          if (
            router.pathname == "/checkout/cart" ||
            router.pathname == "/checkout"
          ) {
            router.reload();
          }
        }, 0);
      });
    }
  };

  const updatePlaceHolder = async (newWordIndex) => {};
  useEffect(() => {
    let str = "Search for ";
    let arr = [];
    let tempstr = "";
    for (let i of str.split("")) {
      tempstr += i;
      arr.push(tempstr);
    }
    for (let i of searchTextArr) {
      for (let j of i.split("")) {
        tempstr += j;
        arr.push(tempstr);
      }
      arr.push(tempstr + " |");
      arr.push(tempstr + " |");
      arr.push(tempstr + " |");
      arr.push(tempstr + " |");
      arr.push(tempstr + " |");
      arr.push(tempstr + " |");
      arr.push(tempstr);
      arr.push(tempstr + " |");
      arr.push(tempstr + " |");
      arr.push(tempstr + " |");
      arr.push(tempstr + " |");
      arr.push(tempstr + " |");
      arr.push(tempstr + " |");
      arr.push(tempstr);
      arr.push(tempstr + " |");
      arr.push(tempstr + " |");
      arr.push(tempstr + " |");
      arr.push(tempstr + " |");
      arr.push(tempstr + " |");
      arr.push(tempstr + " |");
      arr.push(tempstr);

      for (let j of i.split("")) {
        tempstr = tempstr.split("").slice(0, -1).join("");
        arr.push(tempstr);
      }
      // tempstr = "Search for "
    }
    setModifiedPlaceHolder(arr);
    const intervalId = setInterval(() => {
      setsearchTextPlaceholderIndex((prevIndex) => {
        if (prevIndex == 229) {
          return 10;
        } else {
          return (prevIndex + 1) % 230;
        }
      });
    }, 100);

    getCartData();
    document.addEventListener("click", handleClickOutside);
    window.addEventListener("scroll", handleScroll);
    return () => {
      document.removeEventListener("click", handleClickOutside);
      window.removeEventListener("scroll", handleScroll);
      clearInterval(intervalId);
    };
  }, []);
  useEffect(() => {
    // localStorage.removeItem("CartData")
    const getLocalCartData = (event) => {
      // alert("cart Changed")
      setCartData(JSON.parse(localStorage.getItem("CartData")));
      // console.log("lllllllll",JSON.parse(localStorage.getItem("CartData")))
    };

    // setCartData(JSON.parse(localStorage.getItem("CartData")));

    window.addEventListener("localCartChanged", getLocalCartData);

    return () => {
      window.removeEventListener("localCartChanged", getLocalCartData);
    };
  }, []);
  // useEffect(() => {
  //   const handleBeforeUnload = (event) => {
  //     try {
  //       if(event?.target?.activeElement?.href && event.target.activeElement?.href.includes('orientbell')){
  //         return
  //       }
  //       setGetInTouch(true)

  //         const message = "You have unsaved changes. Are you sure you want to leave?";
  //         event.returnValue = message; // Standard for most browsers
  //         return message; // For some older browsers

  //     } catch (error) {
  //       console.log(error)
  //     }
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [router]);
  const handleSearch2 = (e) => {
    if (e == "") {
      // setShowSearchBox(false);
    } else {
      setSearchText(e);
      // setShowSearchBox(true);
      window.location = `/catalogsearch/result/?q=${e}`;
    }
  };
  const handleSearch = (e) => {
    if (e.target.value == "") {
      setSearchText(e.target.value);
      setShowSearchBox(false);
    } else {
      setSearchText(e.target.value);
      setShowSearchBox(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      var raw = JSON.stringify({
        requests: [
          {
            indexName: "oblnewver_english1_categories",
            params: `query=${e.target.value}&hitsPerPage=2&analyticsTags=autocomplete&clickAnalytics=true&numericFilters=include_in_menu%3D1`,
          },
          {
            indexName: "oblnewver_english1_products",
            params: `query=${e.target.value}&hitsPerPage=6&analyticsTags=autocomplete&clickAnalytics=true&facets=%5B%22categories.level0%22%5D&numericFilters=visibility_search%3D1&ruleContexts=%5B%22magento_filters%22%2C%22%22%5D`,
          },
          {
            indexName: "oblnewver_english1_pages",
            params: `query=${e.target.value}&hitsPerPage=2&analyticsTags=autocomplete&clickAnalytics=true`,
          },
          {
            indexName: "wordpress_blog_search_data",
            params: `query=${e.target.value}&hitsPerPage=2&analyticsTags=autocomplete&clickAnalytics=true`,
          },
        ],
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        "https://xldkwu66o5-dsn.algolia.net/1/indexes/*/queries?x-algolia-agent=Algolia%20for%20JavaScript%20(3.35.1)%3B%20Browser%3B%20Magento2%20integration%20(3.1.0)%3B%20autocomplete.js%200.38.0&x-algolia-application-id=XLDKWU66O5&x-algolia-api-key=NDcxNDEwZmM0OWM3YjQ0NTM5Mjc3Njk2YjRkNGJhOWRiNTNjN2E0MGQ2NzJjZTJkN2NhYTZiODE5ZDM3MzMzMXRhZ0ZpbHRlcnM9",
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          const finalResult = JSON.parse(result);
          // console.log(finalResult.results[1]);
          setSearchFooterResult(finalResult.results[1].nbHits);
          const finalArray = [];
          finalResult.results.forEach((element) => {
            finalArray.push(element.hits);
            setSearchHits(finalArray);
          });
        })
        .catch((error) => console.log("error", error));
    }
  };

  const onSearchRender = () => {
    return (
      <>
        <div className="page-wrapper" ref={searchBoxRef}>
          <div className="obl-experience-section auto-search-bar">
            <div className="container">
              <div className="auto-search-row">
                <div className="search-col-left">
                  <div className="aa-dataset">
                    <div className="search-title">Categories</div>
                    {searchHits[0] == undefined ? (
                      <ul></ul>
                    ) : (
                      <ul>
                        {searchHits[0].map((hit, indd) => {
                          return (
                            <li key={indd}>
                              <a
                                href={
                                  hit?.url == undefined
                                    ? "#"
                                    : hit.url.replace(
                                        "https://server.orientbell.com",
                                        "https://www.orientbell.com"
                                      )
                                }
                              >
                                {hit?.path == undefined
                                  ? "No Results"
                                  : hit?.path}
                                <small>
                                  {hit?.product_count == undefined
                                    ? ""
                                    : `(${hit?.product_count})`}
                                </small>
                              </a>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </div>
                  {/* <div className="aa-dataset">
                    <div className="search-title">Pages</div>
                    {searchHits[2] == undefined ? (
                      <ul></ul>
                    ) : (
                      <ul>
                        <li>
                          <a
                            href={
                              searchHits[2][0]?.url == undefined
                                ? "#"
                                : searchHits[2][0]?.url.replace("https://server.orientbell.com", "https://www.orientbell.com")
                            }
                          >
                            {searchHits[2][0]?.name == undefined
                              ? " No Results"
                              : searchHits[2][0]?.name}
                          </a>
                        </li>
                        <li>
                          <a
                            href={
                              searchHits[2][1]?.url == undefined
                                ? "#"
                                : searchHits[2][1]?.url.replace("https://server.orientbell.com", "https://www.orientbell.com")
                            }
                          >
                            {searchHits[2][1]?.name == undefined
                              ? ""
                              : searchHits[2][1]?.name}
                          </a>
                        </li>
                      </ul>
                    )}
                  </div> */}
                  <div className="aa-dataset">
                    <div className="search-title">POSTS</div>
                    {searchHits[3] == undefined ? (
                      <ul></ul>
                    ) : (
                      <ul>
                        <li>
                          <a
                            href={
                              searchHits[3][0]?.permalink == undefined
                                ? "#"
                                : searchHits[3][0]?.permalink.replace(
                                    "https://server.orientbell.com",
                                    "https://www.orientbell.com"
                                  )
                            }
                          >
                            {searchHits[3][0]?.post_title == undefined
                              ? " No Results"
                              : searchHits[3][0]?.post_title}
                          </a>
                        </li>
                        <li>
                          <a
                            href={
                              searchHits[3][1]?.permalink == undefined
                                ? "#"
                                : searchHits[3][1]?.permalink.replace(
                                    "https://server.orientbell.com",
                                    "https://www.orientbell.com"
                                  )
                            }
                          >
                            {searchHits[3][1]?.post_title == undefined
                              ? ""
                              : searchHits[3][1]?.post_title}
                          </a>
                        </li>
                      </ul>
                    )}
                  </div>
                </div>
                <div className="search-col-right">
                  <div className="aa-dataset-products">
                    {searchHits[1] == undefined ? (
                      <div></div>
                    ) : (
                      searchHits[1].map((obj, index) => {
                        return (
                          <div className="search-products-col" key={index}>
                            <a
                              href={obj.url.replace(
                                "https://server.orientbell.com",
                                "https://www.orientbell.com"
                              )}
                            >
                              <div className="thumb">
                                <img src={obj.image_url} />
                              </div>
                              <div className="info">
                                <p>{obj.name}</p> <br />
                                Size : {obj.tile_size}
                                <br />
                                <div className="text">
                                  {obj.categories_without_path[0] +
                                    "," +
                                    obj.categories_without_path[1] +
                                    "," +
                                    obj.categories_without_path[2] +
                                    "..."}
                                </div>
                                <div className="price-search">
                                  {obj.price.INR.default_formated} / sq.meter
                                </div>
                              </div>
                            </a>
                          </div>
                        );
                      })
                    )}
                    {/* <div className="search-products-col">
                      <a href="#">
                        <div className="thumb">
                          <img src="img/tile_calendar_2023.webp" />
                        </div>
                        <div className="info">
                          <p>Tile Calendar 2023</p> <br />
                          Size : 300x300 mm
                          <br />
                          <div className="text">
                            In <strong>Floor</strong> Tiles, All Tiles,{" "}
                          </div>
                        </div>
                      </a>
                    </div> */}
                  </div>
                </div>
                <div className="autocomplete-products-footer">
                  See products in{" "}
                  <a href={`/catalogsearch/result/?q=${searchText}`}>
                    All departments
                  </a>{" "}
                  ({searchFooterResult}) or in{" "}
                  <a
                    href={`/catalogsearch/result/?q=${searchText}#q=${searchText}&hFR[categories.level0][0]=All%20Tiles&idx=oblnewver_english1_products`}
                  >
                    All Tiles
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };
  const [base64content, setbase64content] = useState("");
  const convertToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };
  const handleClick22 = () => {
    const fileInput = document.getElementById("file-upload");
    if (fileInput) {
      fileInput.click();
    }
  };
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const base64String = await convertToBase64(file);
        setbase64content(base64String);
        event.target.value = null;
      } catch (error) {
        console.log("Error converting image to base64:", error);
      }
    }
  };
  const [isHidden, setIsHidden] = useState(false);

  useEffect(() => {
    // Check if "ns" is present in the URL (Client-Side)
    if (typeof window !== "undefined") {
      setIsHidden(window.location.href.includes("store-locator"));
    }
  }, []);
  const Responsive = () => {
    const settings = {
      infinite: true,
      slidesToShow: 7,
      slidesToScroll: 1,
      autoplay: true,
      centerMode: false,
      autoplaySpeed: 3000,
      arrows: true,
      dots: false,
      centerPadding: "0px",
      responsive: [
        {
          breakpoint: 1199,
          settings: {
            arrows: true,
            centerMode: false,
            slidesToShow: 6,
          },
        },
        {
          breakpoint: 991,
          settings: {
            arrows: true,
            centerMode: false,
            slidesToShow: 5,
          },
        },
        {
          breakpoint: 680,
          settings: {
            arrows: true,
            centerMode: false,
            slidesToShow: 4,
          },
        },
        {
          breakpoint: 420,
          settings: {
            arrows: true,
            centerMode: false,
            slidesToShow: 4,
          },
        },
      ],
    };
    if (isHidden) {
      return null;
    }
    return (
      <div className="menu-slider">
        <div className="container">
          <link
            rel="stylesheet"
            type="text/css"
            charSet="UTF-8"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.8.1/slick-theme.min.css"
          />
          <link
            rel="stylesheet"
            type="text/css"
            href="https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css"
          />
          <Slider {...settings} className="category-menu">
            <div>
              <div className="menu-item">
                <a href="https://www.orientbell.com/tiles/floor-tiles">
                  <img src="/floor-tiles-66f6ab2a37097.webp" alt="Image" />
                </a>
                <p>Floor Tiles</p>
              </div>
            </div>
            <div>
              <div className="menu-item">
                <a href="https://www.orientbell.com/tiles/wall-tiles">
                  <img
                    src="/wall-tiles-66f6ab2ab81dd.webp"
                    loading="lazy"
                    alt="Image"
                  />
                </a>
                <p>Wall Tiles</p>
              </div>
            </div>
            <div>
              <div className="menu-item">
                <a href="https://www.orientbell.com/tiles/bathroom-tiles">
                  <img
                    src="/bathroom-tiles-66f6ab2a092c2.webp"
                    loading="lazy"
                    alt="Image"
                  />
                </a>
                <p>Bathroom Tiles</p>
              </div>
            </div>
            <div>
              <div className="menu-item">
                <a href="https://www.orientbell.com/tiles/kitchen-tiles">
                  <img src="/kitchen-tiles-66f6ab28cbe8d.webp" alt="Image" />
                </a>
                <p>Kitchen Tiles</p>
              </div>
            </div>
            <div>
              <div className="menu-item">
                <a href="https://www.orientbell.com/tiles/living-room-tiles">
                  <img src="/room-tiles-66f6ab29658ac.webp" alt="Image" />
                </a>
                <p>Room Tiles</p>
              </div>
            </div>
            <div>
              <div className="menu-item">
                <a href="https://www.orientbell.com/tiles/marble-tiles">
                  <img src="/marble-tiles-66f6ab2840b61.webp" alt="Image" />
                </a>
                <p>Marble Tiles</p>
              </div>
            </div>
            <div>
              <div className="menu-item">
                <a href="https://www.orientbell.com/tiles/wooden-tiles">
                  <img src="/wooden-tiles-66f6ab28202ee.webp" alt="Image" />
                </a>
                <p>Wooden Tiles</p>
              </div>
            </div>
            <div>
              <div className="menu-item">
                <a href="https://www.orientbell.com/tiles/vitrified-tiles">
                  <img src="/vitrified-tile-66f6ab299833b.webp" alt="Image" />
                </a>
                <p>Vitrified Tiles</p>
              </div>
            </div>
            <div>
              <div className="menu-item">
                <a href="https://www.orientbell.com/tiles/ceramic-tiles">
                  <img src="/ceramic-tiles-66f6ab27757ed.webp" alt="Image" />
                </a>
                <p>Ceramic Tiles</p>
              </div>
            </div>
            <div>
              <div className="menu-item">
                <a href="https://www.orientbell.com/tiles/cool-tiles">
                  <img src="/cool-tiles-66f6ab2905c12.webp" alt="Image" />
                </a>
                <p>Cool Tiles</p>
              </div>
            </div>
            <div>
              <div className="menu-item">
                <a href="https://www.orientbell.com/tiles/large-tiles">
                  <img src="/large-tiles-66f6ab27a9099.webp" alt="Image" />
                </a>
                <p>Large Tiles</p>
              </div>
            </div>
          </Slider>
        </div>
      </div>
    );
  };
  return (
    <>
      {/* <Script
        src="//cdn.syteapi.com/assets/imajs/imajs.js?a=9393&sig=618e4f7afc15ba0d1c37852e&languageCode=en_US"
        async
      ></Script> */}
      {/* <Script src="//cdn.syteapi.com/assets/textual-search/syte_textual_search_loader.js?account_id=9393&sig=618e4f7afc15ba0d1c37852e&lang=en_US"></Script> */}
      {base64content && (
        <VisualSearch2
          base64={base64content}
          setbase64content={setbase64content}
        />
      )}
      <div className="page-wrapper header-head-bx">
        <div className="header-section">
          <header>
            <div className="container">
              <div className="header-row flex-class">
                <div className="co-logo">
                  <a href="https://www.orientbell.com/" aria-label="Logo">
                    <img
                      src="https://server.orientbell.com/media/img/logo-white.png"
                      alt="Logo"
                    />
                  </a>
                </div>
                <div className="searchbox flex-class">
                  <div className="search-col">
                    <form
                      onSubmit={(e) => {
                        e.preventDefault();
                        if (searchText.trim()) {
                          window.location = `/catalogsearch/result/?q=${searchText}`;
                        }
                      }}
                    >
                      <div className="form-group">
                        <input
                          type="text"
                          className="search-input"
                          aria-label="Search Box"
                          value={searchText}
                          // onFocus={getfocusfeeds}
                          placeholder={
                            ModifiedPlaceHolder[searchTextPlaceholderIndex]
                          }
                          onChange={(e) => {
                            getfeeds(e);
                          }}
                        />
                        <button
                          type="button"
                          className="sear-btn"
                          aria-label="Search"
                          onClick={(e) => {
                            e.preventDefault();
                            if (searchText) {
                              window.location = `/catalogsearch/result/?q=${searchText}`;
                            }
                            // router.push(`/catalogsearch/result/?q=${searchText}`)
                          }}
                        >
                          <img src="/seach-btn.svg" alt="Search" />
                          <span style={{ display: "none" }}>Search</span>
                        </button>
                        <button
                          type="button"
                          className="mic-box"
                          onClick={startListening}
                        >
                          <img src="/nic-ing.svg" alt="mic-image" />
                        </button>
                      </div>
                    </form>
                  </div>

                  {/* <div className="cameraUpload">
							<a href="#"><img src="/search-icon.svg"/></a>
						</div> */}
                  <div id="cameraUpload" className="cameraUpload">
                    <button
                      onClick={() => {
                        // router.push("/samelook")
                        handleClick22();
                      }}
                      // href="javascript:void(0)"
                      className="--syte-start-camera-upload"
                      data-camera-button-placement="header"
                    >
                      <input
                        id="file-upload"
                        type="file"
                        accept="image/jpeg, image/png, image/tiff" // Restrict the accepted file types
                        onChange={handleFileChange}
                        style={{ display: "none" }} // Hide the input field
                      />
                      <img
                        className="h-icon"
                        src="https://server.orientbell.com/media/img/search-icon.svg"
                        alt="search icon"
                        id="visualsearch1"
                      />
                    </button>
                  </div>
                </div>
                <div className="header-right-col">
                  <div className="top-bar-menu">
                    <ul className="flex-class">
                      <li className="help-call">
                        <a href="tel:1800 - 208 - 1015">
                          <img
                            className="h-icon"
                            src="/call-white.svg"
                            alt="call"
                          />{" "}
                          1800 - 208 - 1015
                        </a>
                      </li>
                      <li className="top-language">
                        <div
                          className="language-bx "
                          onClick={() => setLangModal(true)}
                        >
                          <img
                            className="h-icon"
                            src="/translate-icon-white.svg"
                            alt="traslate-icon"
                          />
                        </div>
                      </li>
                      <li className="top-ac">
                        <a href="https://www.orientbell.com/account">
                          <img
                            className="h-icon"
                            src="/user-white.svg"
                            alt="user"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className="h-main-menu">
                    <ul className="flex-class">
                      <li>
                        <a href={`${config.HEADLESS_BASE_URL}/store-locator`}>
                          <img
                            src="/store-white.svg"
                            alt="Store"
                            id="store-icon"
                          />{" "}
                          Stores
                        </a>
                      </li>
                      <li>
                        <a
                          href={`${config.HEADLESS_BASE_URL}/download-catalogue`}
                          aria-label="category"
                        >
                          <img
                            src="/catalog-white.svg"
                            alt="Catalog"
                            id="catalouge1"
                          />{" "}
                          Catalogue
                        </a>
                      </li>
                      <li className="cart-bx" ref={myComponentRef}>
                        <p onClick={toggleClass}>
                          <span>{cartData ? cartData.items.length : 0}</span>
                          <img src="/cart.svg" alt="Cart" />
                        </p>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="custom-head-mobile flex-class">
                  {/* <div className="custom-head-col catalog-bx">
                    <a href={`${config.HEADLESS_BASE_URL}/download-catalogue`} aria-label="category">
                          <img src="/catalog.svg" alt="Catalog" id="catalouge"/>
                    </a>
                  </div> */}

                  <div
                    className="custom-head-col search-bx"
                    id="search-box-icon"
                  >
                    <a aria-level="fnsm">
                      <img src="/search-mobile-icon1.svg" alt="Search" />
                    </a>
                    <div id="mobile_search_div" className="slidesearch2">
                      <form
                        onSubmit={(e) => {
                          e.preventDefault();
                          if (searchText) {
                            window.location = `/catalogsearch/result/?q=${searchText}`;
                          }
                        }}
                      >
                        <div className="form-group">
                          <input
                            type="text"
                            className="search-input"
                            value={searchText}
                            placeholder={
                              ModifiedPlaceHolder[searchTextPlaceholderIndex]
                            }
                            aria-label="Search Box"
                            // onFocus={getfocusfeeds}
                            onChange={(e) => {
                              getfeeds(e);
                            }}
                          />
                          <button
                            type="button"
                            className="sear-btn"
                            id="m_s_close_btn"
                            aria-label="Search"
                            onClick={(e) => {
                              e.preventDefault();
                              if (searchText) {
                                window.location = `/catalogsearch/result/?q=${searchText}`;
                              }
                            }}
                          >
                            <span style={{ display: "none" }}>Search</span>
                          </button>
                          <button
                            type="button"
                            className="mic-box"
                            onClick={startListening}
                          >
                            <img src="/nic-ing.png" alt="mic-image" />
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                  <div
                    className="custom-head-col language-bx "
                    onClick={() => setLangModal(true)}
                  >
                    <img src="/language.svg" alt="cart Logo" />
                  </div>
                  <div className="custom-head-col cart-bx">
                    <a href={`${config.HEADLESS_BASE_URL}/checkout/cart`}>
                      <span> {cartData ? cartData.items.length : 0}</span>
                      <img src="/cart1.svg" alt="cart Logo" />
                    </a>
                  </div>

                  {/* <div className="custom-head-col cameraUpload-bx">
                  <button
                      
                      className="--syte-start-camera-upload"
                      data-camera-button-placement="header"
                    >
                      <img
                        className="h-icon"
                        src="https://server.orientbell.com/media/img/search-icon.svg"
                        alt="search icon"
                        id="visualsearch"
                      />
                    </button>
                  </div> */}

                  <div className="custom-head-col top-ac">
                    <a href="https://www.orientbell.com/account">
                      <img className="h-icon" src="/user-new.svg" alt="user" />
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div
              className={isToggled ? "mini_cart" : "mini_cart close_min_cart"}
            >
              <div className="close_icon" onClick={toggleClass}>
                <FaRegWindowClose />
              </div>
              <h4>My Cart </h4>
              {cartData?.items?.length > 0 ? (
                <>
                  <div className="mini_flex">
                    <small>{cartData.items.length} items in cart</small>
                    <small>
                      {cartData.prices.grand_total.currency}{" "}
                      {cartData.prices.grand_total.value}
                    </small>
                  </div>
                  <Link href="/checkout" className="btn btn-primary">
                    Proceed To Checkout
                  </Link>
                  <div
                    className="mini_cart_productlist"
                    onClick={() =>
                      setTimeout(() => {
                        setIsToggled(true);
                      }, 0)
                    }
                  >
                    {cartData.items.map((cur, index) => {
                      // console.log(cur)
                      return (
                        <div
                          key={index}
                          className="mini_cart_data"
                          id={cur.product.sku}
                        >
                          <div className="min-cart_row">
                            <div className="mini_cart_data_img">
                              <img src={cur.product.image?.url} />
                            </div>
                            <div className="mini_cart_data_cont">
                              <p>
                                <strong>{cur.product.name}</strong>
                              </p>
                            </div>
                          </div>
                          <div className="mini_cart_itemremove">
                            <small onClick={() => removeFromCart(cur)}>
                              <FaTrashAlt className="deleteICon" />
                            </small>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <Link href="/checkout/cart" className="btn btn-primary">
                    View And Edit Cart
                  </Link>
                  {/* <div closeButton></div> */}
                </>
              ) : (
                <h5>There is no product in your Cart</h5>
              )}
            </div>
          </header>
          {keywordSuggestions.length > 0 && (
            <div className="page-wrapper">
              <div
                className="obl-experience-section auto-search-bar"
                onClick={() => setkeywordSuggestions([])}
              >
                <div className="container">
                  <div className="auto-search-row">
                    <div className="search-col-left">
                      <div className="aa-dataset">
                        <div className="search-title">Suggestions</div>
                        <ul>
                          {keywordSuggestions
                            .filter((cc) => !cc.title)
                            .map((hit, indd) => {
                              return (
                                <li key={indd}>
                                  <a
                                    href={`/catalogsearch/result?q=${hit.autosuggest}`}
                                  >
                                    {" "}
                                    {hit.autosuggest}
                                  </a>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                    <div className="search-col-right">
                      <div className="aa-dataset-products">
                        {!keywordSuggestions.filter((cc) => cc.title).length ? (
                          <div></div>
                        ) : (
                          keywordSuggestions
                            .filter((cc) => cc.title)
                            .map((obj, index) => {
                              return (
                                <div
                                  className="search-products-col"
                                  key={index}
                                >
                                  <a
                                    href={obj.productUrl.replace(
                                      "https://server.orientbell.com",
                                      "https://www.orientbell.com"
                                    )}
                                  >
                                    <div className="thumb">
                                      <img
                                        src={
                                          obj?.imageUrl?.length
                                            ? obj.imageUrl[0]
                                            : ""
                                        }
                                      />
                                    </div>
                                    <div className="info">
                                      <p>{obj.title}</p> <br />
                                      {/* Size : {obj.tileSize}
                              <br /> */}
                                      <div className="price-search">
                                        <div>
                                          {" "}
                                          MRP{" "}
                                          {convertPricePerSqmToSqft(
                                            obj.price
                                          )}{" "}
                                          / sq.ft
                                        </div>
                                        <div>Size {obj.tileSize}</div>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                              );
                            })
                        )}
                      </div>
                    </div>
                    <div className="autocomplete-products-footer">
                      See products
                      <a href={`/catalogsearch/result?q=${searchText}`}>
                        All departments
                      </a>
                      <a href={`/catalogsearch/result?q=${searchText}`}>
                        All Tiles
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="obl-nav-section">
            <div className="container">
              <div className="nav-row">
                <div
                  className={
                    navOpen ? "mobile-icon mobile-icon-open" : "mobile-icon"
                  }
                  onClick={() => setNavOpen(!navOpen)}
                >
                  <div className="mm-ic-box">
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </div>
                <ul className="product-menu flex-class">
                  <li>
                    <span
                      className={
                        isClassToggled[0]
                          ? "menu-arrow sublink-open"
                          : "menu-arrow"
                      }
                      onClick={() => toggleClassInMenu(0)}
                    >
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                    <a
                      onClick={(e) => {
                        if (isClassToggled[0]) {
                          window.location = "https://www.orientbell.com/tiles";
                        }
                        e.preventDefault();
                        toggleClassInMenu2(0);
                      }}
                      href="https://www.orientbell.com/tiles"
                    >
                      All Tiles
                    </a>
                    {isClassToggled[0] && (
                      <div className="obl-submenu-row">
                        <div className="container">
                          <div className="obl-submenu-nav flex-class">
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[0]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(0)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Tiles by Size
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/2x2-tiles">
                                    2x2 Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/2x4-tiles">
                                    2x4 Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/1x1-tiles">
                                    1x1 Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/4x8-tiles">
                                    {" "}
                                    4x8 Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/300x600-tiles">
                                    300x600 mm Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/395x395-tiles">
                                    395x395 mm Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/300x450-tiles">
                                    300x450 mm Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/200x1200-tiles">
                                    200x1200 mm Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/800x800-tiles">
                                    800x800 mm Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/800x1200-tiles">
                                    800x1200 mm Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/800x1600-tiles">
                                    {" "}
                                    800x1600 mm Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/145x600-tiles">
                                    {" "}
                                    145x600 mm Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/195x1200-tiles">
                                    {" "}
                                    195x1200 mm Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/295x295-tiles">
                                    295x295 mm Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/200x300-tiles">
                                    {" "}
                                    200x300 mm Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/250x375-tiles">
                                    {" "}
                                    250x375 mm Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/400x400-tiles">
                                    {" "}
                                    400x400 mm Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/148x600-tiles">
                                    {" "}
                                    148x600 mm Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/1000x1000-tiles">
                                    1000x1000 mm Tiles
                                  </a>
                                </li>

                                <li>
                                  <a href="https://www.orientbell.com/tiles/800x800-tiles">
                                    800x800 mm Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/inspire-xl-1200x1800">
                                    1200x1800 mm Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/granalt-tiles">
                                    800x2400 mm Tiles
                                  </a>
                                </li>

                                <li>
                                  <a href="https://www.orientbell.com/tiles/2x4-tiles">
                                    600x1200 mm Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[1]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(1)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Tiles by Area
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/bathroom-tiles">
                                    Bathroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/kitchen-tiles">
                                    Kitchen Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/parking-tiles">
                                    Parking Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/elevation-tiles">
                                    Elevation Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/bedroom-tiles">
                                    Bedroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/outdoor-tiles">
                                    Outdoor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/terrace-tiles">
                                    Terrace Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/living-room-tiles">
                                    Living Room Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/balcony-tiles">
                                    Balcony Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/swimming-pool-tiles">
                                    Swimming Pool Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/porch-tiles">
                                    Porch Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/office-tiles">
                                    Office Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/pathway-tiles">
                                    Pathway Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/dining-room-tiles">
                                    Dining Room Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/commercial-tiles">
                                    Commercial Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/bar-tiles">
                                    Bar Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/restaurant-tiles">
                                    Restaurant Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/hospital-tiles">
                                    Hospital Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/accent-tiles">
                                    Accent Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/automotive-tiles">
                                    Automotive Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/school-tiles">
                                    School Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/high-traffic-tiles">
                                    High Traffic Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/step-stairs-tiles">
                                    Stairs Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[2]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(2)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Tiles by Design
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/3d-tiles">
                                    3d Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/wooden-tiles">
                                    {" "}
                                    Wooden Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/marble-tiles">
                                    Marble Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/texture-tiles">
                                    {" "}
                                    Texture Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/mosaic-tiles">
                                    Mosaic Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/granite-tiles">
                                    Granite Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/stone-tiles">
                                    {" "}
                                    Stone Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/pattern-tiles">
                                    Pattern Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/cement-tiles">
                                    Cement Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/flower-tiles">
                                    {" "}
                                    Flower Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/travertine-tiles">
                                    Travertine Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/slate-tiles">
                                    {" "}
                                    Slate Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/statuario-tiles">
                                    Statuario Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/plain-tiles">
                                    {" "}
                                    Plain Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/onyx-tiles">
                                    {" "}
                                    Onyx Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/metallic-tiles">
                                    Metallic Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/bottochino-tiles">
                                    Bottochino Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/book-match-tiles">
                                    Book Match Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/geometric-tiles">
                                    Geometric Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/carrara-tiles">
                                    Carrara Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/abstract-tiles">
                                    {" "}
                                    Abstract Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/monochrome-tiles">
                                    Monochrome Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/stylized-tiles">
                                    Stylized Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/brick-tiles">
                                    Brick Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/hexagonal-tiles">
                                    Hexagonal Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/limestone-tiles">
                                    Limestone Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/wooden-plank-tiles">
                                    Plank
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[3]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(3)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Tiles by Type
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles">
                                    Vitrified Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/ceramic-tiles">
                                    Ceramic Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/porcelain-tiles">
                                    Porcelain Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/designer-tiles">
                                    Designer Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/anti-skid-tiles">
                                    Anti Skid Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/digital-tiles">
                                    {" "}
                                    Digital Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/double-charge-tiles">
                                    Double Charge Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/gvt-tiles">
                                    GVT Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/cool-tiles">
                                    {" "}
                                    Cool Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/highlighter-tiles">
                                    Highlighter Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/pgvt-tiles ">
                                    PGVT Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/full-body-vitrified-tiles">
                                    Full Body Vitrified Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/polished-vitrified-tiles">
                                    Polished Vitrified Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/germ-free-tiles">
                                    Germ Free Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/dgvt-tiles">
                                    DGVT Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/forever-tiles">
                                    Scratch Free Tile(Forever Tile)
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/digital-glazed-vitrified-tiles">
                                    Digital Glazed Vitrified Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/stain-resistant-tiles">
                                    Stain Resistant Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/nano-tiles">
                                    Nano Tiles
                                  </a>
                                </li>
                                {/* <li>
                                <a href="https://www.orientbell.com/tiles/printed-double-charge-tiles">
                                  Printed Double Charge Tiles
                                </a>
                              </li> */}
                                <li>
                                  <a href="https://www.orientbell.com/tiles/non-digital-tiles">
                                    {" "}
                                    Non Digital Tiles
                                  </a>
                                </li>
                                {/* <li>
                                <a href="https://www.orientbell.com/tiles/kerinox-tiles">
                                  Kerinox Tiles
                                </a>
                              </li> */}
                                <li>
                                  <a href="https://www.orientbell.com/tiles/anti-static-tiles">
                                    Anti Static Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[4]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(4)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Tiles by Finish
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/matte-finish-tiles">
                                    Matte Finish Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/glossy-tiles">
                                    Glossy Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/sugar-finish-tiles">
                                    Sugar Finish Tiles
                                  </a>
                                </li>
                                {/* <li>
                                <a href="https://www.orientbell.com/tiles/satin-finish-tiles">
                                  Satin Finish Tiles
                                </a>
                              </li> */}
                                <li>
                                  <a href="https://www.orientbell.com/tiles/super-glossy-tiles">
                                    Super Glossy Tiles
                                  </a>
                                </li>
                                {/* <li>
                                <a href="https://www.orientbell.com/tiles/reactive-tiles">
                                  Reactive Tiles
                                </a>
                              </li> */}
                                {/* <li>
                                <a href="https://www.orientbell.com/tiles/lapato-finish-tiles">
                                  Lapato Finish Tiles
                                </a>
                              </li> */}
                              </ul>

                              <div
                                className={
                                  isTitleHeadToggled[5]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(5)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Tiles by Collection
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/sparkle">
                                    Sparkle Tiles Collection
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/estilo">
                                    Estilo Tiles Collection
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/ft-autumn2020">
                                    FT Autumn 2020 Tiles Collection
                                  </a>
                                </li>
                                {/* <li>
                                <a href="https://www.orientbell.com/tiles/tile-collection/zenith">
                                  Zenith Tiles Collection
                                </a>
                              </li> */}
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/sahara">
                                    Sahara Tiles Collection
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/rhino-series-pavers-tiles">
                                    Rhino Tiles Collection
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/duazzle">
                                    Duazzle Tiles Collection
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/inspire">
                                    Inspire Tiles Collection
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/hd-p-elevation">
                                    HD-P Elevation Tiles Collection
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/inspire-planks">
                                    Inspire Planks Tiles Collection
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                  <li>
                    <span
                      className={
                        isClassToggled[1]
                          ? "menu-arrow sublink-open"
                          : "menu-arrow"
                      }
                      onClick={() => toggleClassInMenu(1)}
                    >
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        if (isClassToggled[1]) {
                          window.location =
                            "https://www.orientbell.com/tiles/floor-tiles";
                        }
                        toggleClassInMenu2(1);
                      }}
                      href="https://www.orientbell.com/tiles/floor-tiles"
                    >
                      Floor Tiles
                    </a>
                    {isClassToggled[1] && (
                      <div className="obl-submenu-row">
                        <div className="container">
                          <div className="obl-submenu-nav flex-class">
                            <div className="titleBox">
                              <a href="https://www.orientbell.com/tiles/floor-tiles">
                                <div
                                  className="titleHead whiteTitleHead"
                                  onClick={() => toggleTitleHead(6)}
                                >
                                  View all Floor Tiles
                                </div>
                              </a>
                              <div
                                className={
                                  isTitleHeadToggled[7]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(7)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Tiles by Room Area
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/bathroom-tiles/bathroom-floor-tiles">
                                    Bathroom floor tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/living-room-tiles?cat=80">
                                    Living Room floor tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/kitchen-tiles/kitchen-floor-tiles">
                                    Kitchen floor tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/bedroom-tiles?tiles=floor-tiles ">
                                    Bedroom floor tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/parking-tiles?tiles=floor-tiles ">
                                    Porch/Parking Floor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/dining-room-tiles?tiles=floor-tiles">
                                    Dining Room Floor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/terrace-tiles">
                                    Terrace Floor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/outdoor-tiles/outdoor-floor-tiles ">
                                    Outdoor Floor Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[8]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(8)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Floor Tiles by Size (300 mm ~ 1 ft.)
                                </a>
                              </div>
                              <ul>
                                {/* <li className="walltile-sub">
                                <a href="https://www.orientbell.com/tiles/4x8-tiles?tiles=floor-tiles">
                                  1200X2400 mm Floor Tiles
                                </a>
                              </li> */}
                                {/* <li className="walltile-sub">
                                <a href="https://www.orientbell.com/tiles/4x4-tiles?tiles=floor-tiles">
                                  1200X1200 mm Floor Tiles
                                </a>
                              </li> */}
                                {/* <li className="walltile-sub">
                                <a href="https://www.orientbell.com/tiles/800x1200-tiles?tiles=floor-tiles">
                                  800X1200 mm Floor Tiles
                                </a>
                              </li> */}
                                {/* <li className="walltile-sub">
                                <a href="https://www.orientbell.com/tiles/800x2400-tiles?tiles=floor-tiles">
                                  800x2400 mm Floor Tiles
                                </a>
                              </li> */}
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/800x1600-tiles?tiles=floor-tiles">
                                    800X1600 mm Floor Tiles
                                  </a>
                                </li>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/800x800-tiles?tiles=floor-tiles">
                                    800X800 mm Floor Tiles
                                  </a>
                                </li>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/2x4-tiles?tiles=floor-tiles">
                                    600X1200 mm Floor Tiles
                                  </a>
                                </li>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/2x2-tiles?tiles=floor-tiles">
                                    600x600 mm Floor Tiles
                                  </a>
                                </li>
                                {/* <li className="walltile-sub">
                                <a href="https://www.orientbell.com/tiles/300x600-tiles?tiles=floor-tiles">
                                  300 X 600 mm Floor Tiles
                                </a>
                              </li>{" "} */}
                                <li>
                                  <a href="https://www.orientbell.com/tiles/wooden-tiles?tiles=floor-tiles">
                                    Plank Floor Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[9]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(9)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Popular Floor Tile Categories
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/double-charge-tiles?tiles=floor-tiles">
                                    Double Charge Vitrified Floor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/anti-skid-tiles?tiles=floor-tiles">
                                    Anti Skid Floor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles?tiles=floor-tiles">
                                    Vitrified Floor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/full-body-vitrified-tiles?tiles=floor-tiles">
                                    Full Body Vitrified Floor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/ceramic-tiles?tiles=floor-tiles">
                                    Ceramic Floor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/forever-tiles?tiles=floor-tiles">
                                    Forever Floor Tiles (Scratch Resistant)
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/gvt-tiles?tiles=floor-tiles">
                                    Polished Glazed Vitrified Floor Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[10]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(10)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Popular Floor Tile Designs
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/wooden-tiles?tiles=floor-tiles">
                                    Wooden Floor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/marble-tiles?tiles=floor-tiles">
                                    Marble Floor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/abstract-tiles?tiles=floor-tiles">
                                    Abstract Floor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/elevation-tiles?tiles=floor-tiles">
                                    Brick Floor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/cement-tiles?tiles=floor-tiles">
                                    Cement Floor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/stone-tiles?tiles=floor-tiles">
                                    Stone Floor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/granite-tiles?tiles=floor-tiles">
                                    Granite Floor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/book-match-tiles?tiles=floor-tiles">
                                    Book Match Floor Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[11]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(11)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Recommended Floor Tiles
                                </a>
                              </div>
                              <ul className="titlepattern">
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern1 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/pgvt_calacatta_natura_f1.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/pgvt_calacatta_natura_f1.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/pgvt_calacatta_natura_f1.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/pgvt-calacatta-natura"
                                          className="murllink"
                                        >
                                          PGVT Calacatta Natura
                                        </a>{" "}
                                        <span>600x1200 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern2 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/gft_bdf_rustic_arc_jaisalmer.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/gft_bdf_rustic_arc_jaisalmer.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/gft_bdf_rustic_arc_jaisalmer.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/gft-bdf-rustic-arc-jaiselmer"
                                          className="murllink"
                                        >
                                          GFT BDF Rustic Arc Jaiselmer{" "}
                                        </a>{" "}
                                        <span>600x600 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern3 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/dgvt_hardstone_brown_f2.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/dgvt_hardstone_brown_f2.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/dgvt_hardstone_brown_f2.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/dgvt-hardstone-brown"
                                          className="murllink"
                                        >
                                          DGVT Hardstone Brown
                                        </a>{" "}
                                        <span>195x1200 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <div
                                className={
                                  isTitleHeadToggled[12]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(12)}
                              >
                                <a href="https://www.orientbell.com/tiles/floor-tiles">
                                  Popular Tiles
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[13]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(13)}
                              >
                                <a href="https://www.orientbell.com/tiles/floor-tiles">
                                  #Trending Tiles
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[14]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(14)}
                              >
                                <a href="https://www.orientbell.com/tiles/floor-tiles">
                                  Latest Collection
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[15]
                                    ? "titleHead submenu-open"
                                    : "titleHead header-view-all-tiles"
                                }
                                onClick={() => toggleTitleHead(15)}
                              >
                                <a href="https://www.orientbell.com/tiles/floor-tiles">
                                  View all Floor Tiles
                                </a>
                              </div>
                            </div>
                            <div className="tilecolor">
                              <div
                                className={
                                  isTitleHeadToggled[16]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(16)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Popular Tile Colour
                                </a>
                              </div>
                              <ul className="colorList flex-class">
                                <li>
                                  <a href="https://www.orientbell.com/tiles/white-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch1"></span>{" "}
                                    <span>White</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/black-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch2"></span>{" "}
                                    <span>Black</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/brown-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch4"></span>{" "}
                                    <span>Brown</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/beige-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch5"></span>{" "}
                                    <span>Beige</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/ivory-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch6"></span>{" "}
                                    <span>Ivory</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/cream-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch7"></span>{" "}
                                    <span>Cream</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/yellow-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch8"></span>{" "}
                                    <span>Yellow</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/blue-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch9"></span>{" "}
                                    <span>Blue</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/green-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch10"></span>{" "}
                                    <span>Green</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/grey-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch11"></span>{" "}
                                    <span>Grey</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/pink-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch12"></span>{" "}
                                    <span>Pink</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/red-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch14"></span>{" "}
                                    <span>Red</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/terracotta-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch15"></span>{" "}
                                    <span>Terracotta</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/multi-colour-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch16"></span>{" "}
                                    <span>Multi-colour</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                  <li>
                    <span
                      className={
                        isClassToggled[2]
                          ? "menu-arrow sublink-open"
                          : "menu-arrow"
                      }
                      onClick={() => toggleClassInMenu(2)}
                    >
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </span>

                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        if (isClassToggled[2]) {
                          window.location =
                            "https://www.orientbell.com/tiles/wall-tiles";
                        }
                        toggleClassInMenu2(2);
                      }}
                      href="https://www.orientbell.com/tiles/wall-tiles"
                    >
                      Wall Tiles
                    </a>
                    {isClassToggled[2] && (
                      <div className="obl-submenu-row">
                        <div className="container">
                          <div className="obl-submenu-nav flex-class">
                            <div className="titleBox">
                              <a
                                href="https://www.orientbell.com/tiles/wall-tiles"
                                aria-label="category"
                              >
                                <div
                                  className="titleHead whiteTitleHead"
                                  onClick={() => toggleTitleHead(17)}
                                >
                                  View all Wall Tiles
                                </div>
                              </a>
                              <div className="">
                                <div
                                  className={
                                    isTitleHeadToggled[18]
                                      ? "titleHead submenu-open"
                                      : "titleHead"
                                  }
                                  onClick={() => toggleTitleHead(18)}
                                >
                                  <a
                                    href="#"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Tiles by Space
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <a
                                      href="https://www.orientbell.com/tiles/bathroom-tiles/bathroom-wall-tiles"
                                      aria-label="category"
                                    >
                                      Bathroom Wall tiles
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.orientbell.com/tiles/living-room-tiles/living-room-wall-tiles"
                                      aria-label="category"
                                    >
                                      Living Room Wall tiles
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.orientbell.com/tiles/kitchen-tiles/kitchen-wall-tiles"
                                      aria-label="category"
                                    >
                                      Kitchen Wall tiles
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.orientbell.com/tiles/bedroom-tiles?tiles=wall-tiles"
                                      aria-label="category"
                                    >
                                      Bedroom Wall tiles
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.orientbell.com/tiles/elevation-tiles?tiles=wall-tiles"
                                      aria-label="category"
                                    >
                                      Elevation Tiles
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.orientbell.com/tiles/balcony-tiles?tiles=wall-tiles"
                                      aria-label="category"
                                    >
                                      Balcony Tiles
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://www.orientbell.com/tiles/outdoor-tiles?tiles=wall-tiles"
                                      aria-label="category"
                                    >
                                      Exterior/Outdoor Wall Tiles
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[19]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(19)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Wall Tiles by Size (300 mm ~ 1 ft.)
                                </a>
                              </div>
                              <ul>
                                {/* <li className="walltile-sub">
                                <a href="https://www.orientbell.com/tiles/2x4-tiles?tiles=wall-tiles" aria-label="category">
                                  600X1200 mm Wall Tiles
                                </a>
                              </li> */}
                                {/* <li className="walltile-sub">
                                <a href="https://www.orientbell.com/tiles/2x2-tiles?tiles=wall-tiles">
                                  600x600 mm Wall Tiles
                                </a>
                              </li> */}
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/300x600-tiles?tiles=wall-tiles">
                                    300X600 mm Wall Tiles
                                  </a>
                                </li>
                                {/* <li className="walltile-sub">
                                <a href="https://www.orientbell.com/tiles/4x8-tiles?tiles=wall-tiles">
                                  1200X2400 mm Wall Tiles
                                </a>
                              </li> */}
                                {/* <li className="walltile-sub">
                                <a href="https://www.orientbell.com/tiles/4x4-tiles?tiles=wall-tiles">
                                  1200X1200 mm Wall Tiles
                                </a>
                              </li> */}
                                {/* <li className="walltile-sub">
                                <a href="https://www.orientbell.com/tiles/800x800-tiles?tiles=wall-tiles">
                                  800X800 mm Wall Tiles
                                </a>
                              </li> */}
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/300x450-tiles?tiles=wall-tiles">
                                    300X450 mm Wall Tiles
                                  </a>
                                </li>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/200X300-tiles?tiles=wall-tiles">
                                    200X300 mm Wall Tiles
                                  </a>
                                </li>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/250X375-tiles?tiles=wall-tiles">
                                    250X375 mm Wall Tiles
                                  </a>
                                </li>
                                {/* <li></li> */}
                                {/* <li>
                                
                                <a href="https://www.orientbell.com/tiles/wooden-tiles?tiles=wall-tiles">
                                  Plank Wall Tiles
                                </a>
                              </li> */}
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[20]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(20)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Popular Wall Tile Categories
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/designer-tiles?tiles=wall-tiles">
                                    Designer Wall Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/ceramic-tiles?tiles=wall-tiles">
                                    Ceramic Wall Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles?tiles=wall-tiles">
                                    Vitrified Wall Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/gvt-tiles?tiles=wall-tiles">
                                    Glazed Vitrified Wall Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/highlighter-tiles?tiles=wall-tiles">
                                    Highlighter Wall Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/digital-tiles?tiles=wall-tiles">
                                    Digital Wall Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/polished-vitrified-tiles?tiles=wall-tiles">
                                    Polished Vitrified Wall Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/porcelain-tiles?tiles=wall-tiles">
                                    Porcelain Wall Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[21]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(21)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Wall Tile Designs
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/wooden-tiles?tiles=wall-tiles">
                                    Wooden Wall Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/marble-tiles?tiles=wall-tiles">
                                    Marble Wall Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/3d-tiles?tiles=wall-tiles">
                                    3D Wall Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/mosaic-tiles?tiles=wall-tiles">
                                    Mosaic Wall Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/flower-tiles?tiles=wall-tiles">
                                    Floral Wall Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/elevation-tiles?tiles=wall-tiles">
                                    Brick Wall Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/plain-tiles?tiles=wall-tiles">
                                    Plain Wall Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/geometric-tiles?tiles=wall-tiles">
                                    Geometric Wall Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[22]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(22)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Recommended Wall Tiles
                                </a>
                              </div>
                              <ul className="titlepattern">
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern1 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh_amelia_decor_hl.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh_amelia_decor_hl.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh_amelia_decor_hl.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/odh-amelia-decor-hl-015005764461817011m"
                                          className="murllink"
                                        >
                                          ODH Amelia Decor HL
                                        </a>{" "}
                                        <span>300x600 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern2 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/ehm_slump_block_cotto.png?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/ehm_slump_block_cotto.png 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/ehm_slump_block_cotto.png"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/ehm-slump-block-cotto"
                                          className="murllink"
                                        >
                                          EHM Slump Block Cotto
                                        </a>{" "}
                                        <span>300x600 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern3 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh-bottichino-beige-hl.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh-bottichino-beige-hl.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh-bottichino-beige-hl.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/odh-bottichino-beige-hl-015005655541425011m"
                                          className="murllink"
                                        >
                                          ODH Bottichino Beige HL
                                        </a>{" "}
                                        <span>300x450 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <div
                                className={
                                  isTitleHeadToggled[23]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(23)}
                              >
                                <a href="https://www.orientbell.com/tiles/wall-tiles?popularity=101">
                                  Popular Wall Tiles
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[24]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(24)}
                              >
                                <a href="https://www.orientbell.com/tiles/wall-tiles">
                                  #Trending Wall Tiles
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[25]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(25)}
                              >
                                <a href="https://www.orientbell.com/tiles/wall-tiles">
                                  Latest Wall Tile Collection
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[26]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(26)}
                              >
                                <a href="https://www.orientbell.com/tiles/wall-tiles">
                                  View All Wall Tiles
                                </a>
                              </div>
                            </div>
                            <div className="tilecolor">
                              <div
                                className={
                                  isTitleHeadToggled[27]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(27)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Popular Tile Colour
                                </a>
                              </div>
                              <ul className="colorList flex-class">
                                <li>
                                  <a href="https://www.orientbell.com/tiles/white-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch1"></span>{" "}
                                    <span>White</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/black-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch2"></span>{" "}
                                    <span>Black</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/brown-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch4"></span>{" "}
                                    <span>Brown</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/beige-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch5"></span>{" "}
                                    <span>Beige</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/ivory-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch6"></span>{" "}
                                    <span>Ivory</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/cream-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch7"></span>{" "}
                                    <span>Cream</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/yellow-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch8"></span>{" "}
                                    <span>Yellow</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/blue-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch9"></span>{" "}
                                    <span>Blue</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/green-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch10"></span>{" "}
                                    <span>Green</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/grey-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch11"></span>{" "}
                                    <span>Grey</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/pink-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch12"></span>{" "}
                                    <span>Pink</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/red-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch14"></span>{" "}
                                    <span>Red</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/terracotta-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch15"></span>{" "}
                                    <span>Terracotta</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/multi-colour-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch16"></span>{" "}
                                    <span>Multi-colour</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                  <li>
                    <span
                      className={
                        isClassToggled[3]
                          ? "menu-arrow sublink-open"
                          : "menu-arrow"
                      }
                      onClick={() => toggleClassInMenu(3)}
                    >
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        if (isClassToggled[3]) {
                          window.location =
                            "https://www.orientbell.com/tiles/bathroom-tiles";
                        }
                        toggleClassInMenu2(3);
                      }}
                      href="https://www.orientbell.com/tiles/bathroom-tiles"
                    >
                      Bathroom Tiles
                    </a>
                    {isClassToggled[3] && (
                      <div className="obl-submenu-row">
                        <div className="container">
                          <div className="obl-submenu-nav flex-class">
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[28]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(28)}
                              >
                                <a href="https://www.orientbell.com/tiles/bathroom-tiles">
                                  View All Bathroom Tiles
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/bathroom-tiles/bathroom-wall-tiles">
                                    Bathroom wall Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/bathroom-tiles/bathroom-floor-tiles">
                                    Bathroom Floor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/anti-skid-tiles?tiles=bathroom-tiles">
                                    Anti-skid Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[29]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(29)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Bathroom Tiles by Size (300mm~1ft.)
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/300x600-tiles?tiles=bathroom-tiles">
                                    300X600 mm Bathroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/1x1-tiles?tiles=bathroom-tiles">
                                    300X300 mm Bathroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/2x2-tiles?tiles=bathroom-tiles">
                                    600X600 mm Bathroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/300x450-tiles?tiles=bathroom-tiles">
                                    300X450 mm Bathroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/2x4-tiles?tiles=bathroom-tiles">
                                    600X1200 mm Bathroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/200x300-tiles?tiles=bathroom-tiles">
                                    200X300 mm Bathroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/250x375-tiles?tiles=bathroom-tiles">
                                    250X375 mm Bathroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/wooden-tiles?tiles=bathroom-tiles">
                                    Planks Bathroom Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[30]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(30)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Bathroom Tile Categories
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/anti-skid-tiles?tiles=bathroom-tiles">
                                    Anti Skid Bathroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/ceramic-tiles?tiles=bathroom-tiles">
                                    Ceramic Bathroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles?tiles=bathroom-tiles">
                                    Vitrified Bathroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/highlighter-tiles?tiles=bathroom-tiles">
                                    Highlighter Bathroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/designer-tiles?tiles=bathroom-tiles">
                                    Designer Bathroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/light-tiles?tiles=bathroom-tiles">
                                    Light Bathroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/gvt-tiles?tiles=bathroom-tiles">
                                    Glazed Vitrified Bathroom Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[31]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(31)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Popular Bathroom Tile Designs
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/3d-tiles?tiles=bathroom-tiles">
                                    3D Bathroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/wooden-tiles?tiles=bathroom-tiles">
                                    Wooden Bathroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/mosaic-tiles?tiles=bathroom-tiles">
                                    Mosaic Bathroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/geometric-tiles?tiles=bathroom-tiles">
                                    Geometric Bathroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/flower-tiles?tiles=bathroom-tiles">
                                    Floral Bathroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/plain-tiles?tiles=bathroom-tiles">
                                    Plain Bathroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/texture-tiles?tiles=bathroom-tiles">
                                    Texture Bathroom Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/marble-tiles?tiles=bathroom-tiles">
                                    Marble Bathroom Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[32]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(32)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Recommended Bathroom Tiles Concepts
                                </a>
                              </div>
                              <ul className="titlepattern">
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern1 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh-jagna-hl.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh-jagna-hl.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh-jagna-hl.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/odh-jagna-hl-015005753951343011m"
                                          className="murllink"
                                        >
                                          ODH Jagna HL
                                        </a>{" "}
                                        <span>300x600 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern2 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/gft_sph_sawtooth_blue_hl.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/gft_sph_sawtooth_blue_hl.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/gft_sph_sawtooth_blue_hl.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/gft-sph-sawtooth-blue-hl"
                                          className="murllink"
                                        >
                                          GFT SPH Sawtooth Blue HL
                                        </a>{" "}
                                        <span>300x450 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern3 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh_piano_floral_hl.png?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh_piano_floral_hl.png 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh_piano_floral_hl.png"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/odh-piano-floral-hl"
                                          className="murllink"
                                        >
                                          ODH Piano Floral HL{" "}
                                        </a>{" "}
                                        <span>300x600 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <div
                                className={
                                  isTitleHeadToggled[33]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(33)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_area=103&amp;popularity=101&amp;mt=b">
                                  Popular Bathroom Tiles
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[34]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(34)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_area=103&amp;mt=b">
                                  #Trending Bathroom Tiles
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[35]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(35)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_area=103&amp;mt=b">
                                  Latest Collection
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[36]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(36)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_area=103&amp;mt=b">
                                  View all Bathroom Tiles
                                </a>
                              </div>
                            </div>
                            <div className="tilecolor">
                              <div
                                className={
                                  isTitleHeadToggled[37]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(37)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Popular Tile Colour
                                </a>
                              </div>
                              <ul className="colorList flex-class">
                                <li>
                                  <a href="https://www.orientbell.com/tiles/bathroom-tiles/white-bathroom-tiles">
                                    <span className="swatch swatch1"></span>{" "}
                                    <span>White</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/bathroom-tiles/black-bathroom-tiles">
                                    <span className="swatch swatch2"></span>{" "}
                                    <span>Black</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/bathroom-tiles/brown-bathroom-tiles">
                                    <span className="swatch swatch4"></span>{" "}
                                    <span>Brown</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/beige-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch5"></span>{" "}
                                    <span>Beige</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/ivory-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch6"></span>{" "}
                                    <span>Ivory</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/cream-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch7"></span>{" "}
                                    <span>Cream</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/yellow-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch8"></span>{" "}
                                    <span>Yellow</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/bathroom-tiles/blue-bathroom-tiles">
                                    <span className="swatch swatch9"></span>{" "}
                                    <span>Blue</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/green-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch10"></span>{" "}
                                    <span>Green</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/bathroom-tiles/grey-bathroom-tiles">
                                    <span className="swatch swatch11"></span>{" "}
                                    <span>Grey</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/bathroom-tiles/pink-bathroom-tiles">
                                    <span className="swatch swatch12"></span>{" "}
                                    <span>Pink</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/red-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch14"></span>{" "}
                                    <span>Red</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/terracotta-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch15"></span>{" "}
                                    <span>Terracotta</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/multi-colour-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch16"></span>{" "}
                                    <span>Multi-colour</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                  <li>
                    <span
                      className={
                        isClassToggled[4]
                          ? "menu-arrow sublink-open"
                          : "menu-arrow"
                      }
                      onClick={() => toggleClassInMenu(4)}
                    >
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        if (isClassToggled[4]) {
                          window.location =
                            "https://www.orientbell.com/tiles/kitchen-tiles";
                        }
                        toggleClassInMenu2(4);
                      }}
                      href="https://www.orientbell.com/tiles/kitchen-tiles"
                    >
                      Kitchen Tiles
                    </a>
                    {isClassToggled[4] && (
                      <div className="obl-submenu-row">
                        <div className="container">
                          <div className="obl-submenu-nav flex-class">
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[38]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(38)}
                              >
                                <a href="https://www.orientbell.com/tiles/kitchen-tiles">
                                  View All Kitchen Tiles
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/kitchen-tiles/kitchen-floor-tiles">
                                    Kitchen Floor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/kitchen-tiles/kitchen-wall-tiles">
                                    Kitchen wall Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/anti-skid-tiles?tiles=kitchen-tiles">
                                    Anti-skid Kitchen Tiles
                                  </a>
                                </li>
                              </ul>
                              <div
                                className={
                                  isTitleHeadToggled[39]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(39)}
                              >
                                <a href="#">Backsplashes Kitchen tiles</a>
                              </div>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[40]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(40)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Kitchen Tiles by Size
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/300x600-tiles?tiles=kitchen-tiles">
                                    300X600 mm Kitchen Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/2x2-tiles?tiles=kitchen-tiles">
                                    600X600 mm Kitchen Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/300x450-tiles?tiles=kitchen-tiles">
                                    300X450 mm Kitchen Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/1x1-tiles?tiles=kitchen-tiles">
                                    300X300 mm Kitchen Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/2x4-tiles?tiles=kitchen-tiles">
                                    600X1200 mm Kitchen Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/200x300-tiles?tiles=kitchen-tiles">
                                    200X300 mm Kitchen Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/395x395-tiles?tiles=kitchen-tiles">
                                    395X395 mm Kitchen Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/wooden-tiles?tiles=kitchen-tiles">
                                    Plank Kitchen Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[41]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(41)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Kitchen Tile Categories
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/ceramic-tiles?tiles=kitchen-tiles">
                                    Ceramic Kitchen Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/highlighter-tiles?tiles=kitchen-tiles">
                                    Highlighter Kitchen Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles?tiles=kitchen-tiles">
                                    Vitrified Kitchen Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/designer-tiles?tiles=kitchen-tiles">
                                    Designer Kitchen Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/light-tiles?tiles=kitchen-tiles">
                                    Light Kitchen Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/dark-tiles?tiles=kitchen-tiles">
                                    Dark Kitchen Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/gvt-tiles?tiles=kitchen-tiles">
                                    Glazed Vitrified Kitchen Tiles
                                  </a>
                                </li>{" "}
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/polished-vitrified-tiles?tiles=kitchen-tiles">
                                    Polished Vitrified Kitchen Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[42]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(42)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Popular Kitchen Tile Designs
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/flower-tiles?tiles=kitchen-tiles">
                                    Floral Kitchen Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/mosaic-tiles?tiles=kitchen-tiles">
                                    Mosaic Kitchen Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/geometric-tiles?tiles=kitchen-tiles">
                                    Geometric Kitchen Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/3d-tiles?tiles=kitchen-tiles">
                                    3D Kitchen Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/stylized-tiles?tiles=kitchen-tiles">
                                    Stylized Kitchen Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/wooden-tiles?tiles=kitchen-tiles">
                                    Wooden Kitchen Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/plain-tiles?tiles=kitchen-tiles">
                                    Plain Kitchen Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/texture-tiles?tiles=kitchen-tiles">
                                    Texture Kitchen Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[43]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(43)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Recommended Kitchen Tiles Concepts
                                </a>
                              </div>
                              <ul className="titlepattern">
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern1 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh_diamond_cross_hl.png?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh_diamond_cross_hl.png 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh_diamond_cross_hl.png"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/odh-diamond-cross-hl"
                                          className="murllink"
                                        >
                                          ODH Diamond Cross HL
                                        </a>{" "}
                                        <span>300x600 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern2 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/gft_sph_geometric_grey_hl.png?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/gft_sph_geometric_grey_hl.png 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/gft_sph_geometric_grey_hl.png"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/gft-sph-geometric-grey-hl"
                                          className="murllink"
                                        >
                                          GFT SPH Geometric Grey HL
                                        </a>{" "}
                                        <span>300x450 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern3 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh_trapezoid_blue_hl.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh_trapezoid_blue_hl.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh_trapezoid_blue_hl.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/odh-trapezoid-blue-hl"
                                          className="murllink"
                                        >
                                          ODH Trapezoid Blue HL
                                        </a>{" "}
                                        <span>300x600 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <div
                                className={
                                  isTitleHeadToggled[44]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(44)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_area=104&amp;popularity=101&amp;mt=k">
                                  Popular Kitchen Tiles
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[45]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(45)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_area=104&amp;mt=k&amp;trending=1">
                                  #Trending Kitchen Tiles
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[46]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(46)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_area=104&amp;mt=k&amp;product_list_dir=asc">
                                  Latest Collection
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[47]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(47)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_area=104&amp;mt=k">
                                  View all Kitchen Tiles
                                </a>
                              </div>
                            </div>
                            <div className="tilecolor">
                              <div
                                className={
                                  isTitleHeadToggled[48]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(48)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Popular Tile Colour
                                </a>
                              </div>
                              <ul className="colorList flex-class">
                                <li>
                                  <a href="https://www.orientbell.com/tiles/kitchen-tiles/white-kitchen-tiles">
                                    <span className="swatch swatch1"></span>{" "}
                                    <span>White</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/kitchen-tiles/black-kitchen-tiles">
                                    <span className="swatch swatch2"></span>{" "}
                                    <span>Black</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/brown-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch4"></span>{" "}
                                    <span>Brown</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/kitchen-tiles/beige-kitchen-tiles">
                                    <span className="swatch swatch5"></span>{" "}
                                    <span>Beige</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/ivory-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch6"></span>{" "}
                                    <span>Ivory</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/cream-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch7"></span>{" "}
                                    <span>Cream</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/yellow-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch8"></span>{" "}
                                    <span>Yellow</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/kitchen-tiles/blue-kitchen-tiles">
                                    <span className="swatch swatch9"></span>{" "}
                                    <span>Blue</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tiles/kitchen-tiles/green-kitchen-tiles">
                                    <span className="swatch swatch10"></span>{" "}
                                    <span>Green</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/kitchen-tiles/grey-kitchen-tiles">
                                    <span className="swatch swatch11"></span>{" "}
                                    <span>Grey</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/kitchen-tiles/pink-kitchen-tiles">
                                    <span className="swatch swatch12"></span>{" "}
                                    <span>Pink</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/red-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch14"></span>{" "}
                                    <span>Red</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/terracotta-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch15"></span>{" "}
                                    <span>Terracotta</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/multi-colour-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch16"></span>{" "}
                                    <span>Multi-colour</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                  <li>
                    <span
                      className={
                        isClassToggled[5]
                          ? "menu-arrow sublink-open"
                          : "menu-arrow"
                      }
                      onClick={() => toggleClassInMenu(5)}
                    >
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        if (isClassToggled[5]) {
                          window.location =
                            "https://www.orientbell.com/tiles/living-room-tiles";
                        }
                        toggleClassInMenu2(5);
                      }}
                      href="https://www.orientbell.com/tiles/living-room-tiles"
                    >
                      Room Tiles
                    </a>
                    {isClassToggled[5] && (
                      <div className="obl-submenu-row">
                        <div className="container">
                          <div className="obl-submenu-nav flex-class">
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[49]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(49)}
                              >
                                <a href="https://www.orientbell.com/tiles/living-room-tiles">
                                  Living Room Tiles
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/bedroom-tiles">
                                    Bed Room Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/dining-room-tiles">
                                    Dining Room Tiles
                                  </a>
                                </li>
                              </ul>
                              <div className="">
                                <div
                                  className={
                                    isTitleHeadToggled[50]
                                      ? "titleHead submenu-open"
                                      : "titleHead"
                                  }
                                  onClick={() => toggleTitleHead(50)}
                                >
                                  <a
                                    href="#"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Hall Tiles
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <a href="https://www.orientbell.com/tiles/bedroom-tiles?tiles=living-room-tiles">
                                      Bedroom Floor Tiles
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://www.orientbell.com/tiles/wall-tiles?tiles=living-room-tiles">
                                      Bedroom Wall Tiles
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://www.orientbell.com/tiles/floor-tiles?tiles=living-room-tiles">
                                      Living Room Floor Tiles
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://www.orientbell.com/tiles/living-room-tiles/living-room-wall-tiles">
                                      Living Room Wall Tiles
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://www.orientbell.com/tiles/accent-tiles?tiles=living-room-tiles">
                                      Accent Wall Living Room Tiles
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[51]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(51)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Tiles by Size
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/2x4-tiles?tiles=living-room-tiles">
                                    600x1200 mm Living Room Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/2x2-tiles?tiles=living-room-tiles">
                                    600x600 mm Living Room Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/4x8-tiles?tiles=living-room-tiles">
                                    1200x2400 mm Living Room Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/800x1600-tiles?tiles=living-room-tiles">
                                    800x1600 mm Living Room Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/800x1200-tiles?tiles=living-room-tiles">
                                    800x1200 mm Living Room Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/800x800-tiles?tiles=living-room-tiles">
                                    800X800 mm Living Room Tiles
                                  </a>
                                </li>
                                {/* <li>
                                <a href="https://www.orientbell.com/tiles/4x4-tiles?tiles=living-room-tiles">
                                  1200x1200 mm Living Room Tiles
                                </a>
                              </li> */}
                                <li>
                                  <a href="https://www.orientbell.com/tiles/300x600-tiles?tiles=living-room-tiles">
                                    300x600 mm Living Room Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/wooden-tiles?tiles=living-room-tiles">
                                    Planks Living Room Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[52]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(52)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Room Tile Categories
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/gvt-tiles?tiles=living-room-tiles">
                                    Glazed Vitrified Living RoomTiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles?tiles=living-room-tiles">
                                    Vitrified Living RoomTiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/ceramic-tiles?tiles=living-room-tiles">
                                    Ceramic Living RoomTiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/pgvt-tiles?tiles=living-room-tiles">
                                    Polished Glazed Vitrified Living RoomTiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/double-charge-tiles?tiles=living-room-tiles">
                                    Double Charge Living RoomTiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/dgvt-tiles?tiles=living-room-tiles">
                                    Digital Glazed Vitrified Living RoomTiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/designer-tiles?tiles=living-room-tiles">
                                    Designer Living RoomTiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/dgvt-tiles?tiles=living-room-tiles">
                                    Digital Living RoomTiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[53]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(53)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Popular Tile Designs
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/marble-tiles?tiles=living-room-tiles">
                                    Marble Living Room Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/wooden-tiles?tiles=living-room-tiles">
                                    Wooden Living Room Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/granite-tiles?tiles=living-room-tiles">
                                    Granite Living Room Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/book-match-tiles?tiles=living-room-tiles">
                                    Bookmatch Marble Living Room Tiles
                                  </a>
                                </li>{" "}
                                <li>
                                  <a href="https://www.orientbell.com/tiles/cement-tiles?tiles=living-room-tiles">
                                    Cement Living Room Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/onyx-tiles?tiles=living-room-tiles">
                                    Onyx Living Room Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/plain-tiles?tiles=living-room-tiles">
                                    Plain Living Room Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/mosaic-tiles?tiles=living-room-tiles">
                                    Mosaic Living Room Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/texture-tiles?tiles=living-room-tiles">
                                    Texture Living Room Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[54]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(54)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Recommended Tiles for you
                                </a>
                              </div>
                              <ul className="titlepattern">
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern1 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/pgvt_brown_streak_bianco_f1.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/pgvt_brown_streak_bianco_f1.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/pgvt_brown_streak_bianco_f1.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/pgvt-brown-streak-binaco"
                                          className="murllink"
                                        >
                                          PGVT Brown Streak Binaco
                                        </a>{" "}
                                        <span>600x1200 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern2 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/dgvt-rio-wood.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/dgvt-rio-wood.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/dgvt-rio-wood.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/dgvt-rio-wood-025606656690566361m"
                                          className="murllink"
                                        >
                                          DGVT Rio Wood
                                        </a>{" "}
                                        <span>600x600 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern3 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/lara_gold.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/lara_gold.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/lara_gold.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/lara-gold-marble-double-charge-vitrified-floor-tiles"
                                          className="murllink"
                                        >
                                          Lara Gold
                                        </a>{" "}
                                        <span>600x600 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <div
                                className={
                                  isTitleHeadToggled[55]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(55)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_area=105&amp;popularity=101&amp;mt=r">
                                  Popular Tiles
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[56]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(56)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_area=105&amp;mt=r&amp;trending=1">
                                  #Trending Tiles
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[57]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(57)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_area=105&amp;mt=r&amp;product_list_dir=asc">
                                  Latest Collection
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[58]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(58)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_area=105&amp;mt=r">
                                  View all Tiles
                                </a>
                              </div>
                            </div>
                            <div className="tilecolor">
                              <div
                                className={
                                  isTitleHeadToggled[59]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(59)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Popular Tile Colour
                                </a>
                              </div>
                              <ul className="colorList flex-class">
                                <li>
                                  <a href="https://www.orientbell.com/tiles/white-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch1"></span>{" "}
                                    <span>White</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/black-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch2"></span>{" "}
                                    <span>Black</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/brown-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch4"></span>{" "}
                                    <span>Brown</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/beige-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch5"></span>{" "}
                                    <span>Beige</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/ivory-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch6"></span>{" "}
                                    <span>Ivory</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/cream-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch7"></span>{" "}
                                    <span>Cream</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/yellow-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch8"></span>{" "}
                                    <span>Yellow</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/blue-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch9"></span>{" "}
                                    <span>Blue</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/green-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch10"></span>{" "}
                                    <span>Green</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/grey-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch11"></span>{" "}
                                    <span>Grey</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/pink-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch12"></span>{" "}
                                    <span>Pink</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/red-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch14"></span>{" "}
                                    <span>Red</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/terracotta-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch15"></span>{" "}
                                    <span>Terracotta</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/multi-colour-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch16"></span>{" "}
                                    <span>Multi-colour</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                  <li>
                    <span
                      className={
                        isClassToggled[6]
                          ? "menu-arrow sublink-open"
                          : "menu-arrow"
                      }
                      onClick={() => toggleClassInMenu(6)}
                    >
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        if (isClassToggled[6]) {
                          window.location =
                            "https://www.orientbell.com/tiles/marble-tiles";
                        }
                        toggleClassInMenu2(6);
                      }}
                      href="https://www.orientbell.com/tiles/marble-tiles"
                    >
                      Marble Tiles
                    </a>
                    {isClassToggled[6] && (
                      <div className="obl-submenu-row">
                        <div className="container">
                          <div className="obl-submenu-nav flex-class">
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[60]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(60)}
                              >
                                <a href="https://www.orientbell.com/tiles/marble-tiles">
                                  Marble Tiles
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/marble-tiles?cat=80">
                                    Marble Floor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/marble-tiles?cat=75">
                                    Marble Wall Tiles
                                  </a>
                                </li>
                              </ul>
                              <div className="">
                                <div
                                  className={
                                    isTitleHeadToggled[61]
                                      ? "titleHead submenu-open"
                                      : "titleHead"
                                  }
                                  onClick={() => toggleTitleHead(61)}
                                >
                                  <a
                                    href="#"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Marble Tile By Spaces
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <a href="https://www.orientbell.com/tiles/marble-tiles?tile_area=105">
                                      Living room Marble Tiles
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://www.orientbell.com/tiles/marble-tiles?tile_area=106">
                                      Bedroom Marble Tiles
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://www.orientbell.com/tiles/marble-tiles?tile_area=103">
                                      Bathroom Marble Tiles
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://www.orientbell.com/tiles/marble-tiles?tile_area=104">
                                      Kitchen Marble Tiles
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[62]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(62)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Marble Tiles by Size
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/marble-tiles?tile_size=262">
                                    600x1200 mm Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/marble-tiles?tile_size=264">
                                    600X600 mm tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/marble-tiles?tile_size=455">
                                    800x2400 mm tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/marble-tiles?tile_size=263">
                                    800X800 mm Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/marble-tiles?tile_size=266">
                                    300X600 mm tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/marble-tiles?tile_size=267">
                                    300X450 mm tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/marble-tiles?tile_size=268">
                                    300x300 mm Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[63]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(63)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Popular Marble Finishes
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/marble-tiles?cat=216">
                                    Glossy Finish
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/marble-tiles?cat=219">
                                    Super Glossy Finish
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/marble-tiles?cat=215">
                                    Matte Finish
                                  </a>
                                </li>
                                {/* <li>
                                <a href="https://www.orientbell.com/tiles/marble-tiles?cat=218">
                                  Satin Matte Finish
                                </a>
                              </li> */}
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[64]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(64)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Popular Marble tile Colour
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/white-marble-tiles">
                                    White Marble Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/green-marble-tiles">
                                    Green Marble Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/pink-marble-tiles">
                                    Pink Marble Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/grey-marble-tiles">
                                    Grey Marble Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/red-marble-tiles">
                                    Red Marble Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/brown-marble-tiles">
                                    Brown Marble Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/beige-marble-tiles">
                                    Beige Marble Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/blue-marble-tiles">
                                    Blue Marble Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/black-marble-tiles">
                                    Black Marble Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <ul className="titlepattern">
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern1 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/pgvt_brown_streak_bianco_f1.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/pgvt_brown_streak_bianco_f1.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/pgvt_brown_streak_bianco_f1.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/pgvt-brown-streak-binaco"
                                          className="murllink"
                                        >
                                          PGVT Brown Streak Binaco
                                        </a>{" "}
                                        <span>600x1200 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern2 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/dgvt-rio-wood.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/dgvt-rio-wood.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/dgvt-rio-wood.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/dgvt-rio-wood-025606656690566361m"
                                          className="murllink"
                                        >
                                          DGVT Rio Wood
                                        </a>{" "}
                                        <span>600x600 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern3 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/lara_gold.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/lara_gold.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/lara_gold.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/lara-gold-marble-double-charge-vitrified-floor-tiles"
                                          className="murllink"
                                        >
                                          Lara Gold
                                        </a>{" "}
                                        <span>600x600 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <div
                                className={
                                  isTitleHeadToggled[65]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(65)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_design=354&amp;popularity=101&amp;mt=w">
                                  Popular Tiles
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[66]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(66)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_design=354&amp;mt=w&amp;trending=1">
                                  #Trending Tiles
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[67]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(67)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_design=354&amp;mt=w&amp;product_list_dir=asc">
                                  Latest Collection
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[68]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(68)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_design=354&amp;mt=w">
                                  View all Tiles
                                </a>
                              </div>
                            </div>
                            <div className="tilecolor">
                              <div
                                className={
                                  isTitleHeadToggled[69]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(69)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Popular Tile Colour
                                </a>
                              </div>
                              <ul className="colorList flex-class">
                                <li>
                                  <a href="https://www.orientbell.com/tiles/white-marble-tiles">
                                    <span className="swatch swatch1"></span>{" "}
                                    <span>White</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/black-marble-tiles">
                                    <span className="swatch swatch2"></span>{" "}
                                    <span>Black</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/brown-marble-tiles">
                                    <span className="swatch swatch4"></span>{" "}
                                    <span>Brown</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/beige-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch5"></span>{" "}
                                    <span>Beige</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/ivory-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch6"></span>{" "}
                                    <span>Ivory</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/cream-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch7"></span>{" "}
                                    <span>Cream</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/yellow-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch8"></span>{" "}
                                    <span>Yellow</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/blue-marble-tiles">
                                    <span className="swatch swatch9"></span>{" "}
                                    <span>Blue</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/green-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch10"></span>{" "}
                                    <span>Green</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/grey-marble-tiles">
                                    <span className="swatch swatch11"></span>{" "}
                                    <span>Grey</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/pink-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch12"></span>{" "}
                                    <span>Pink</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/red-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch14"></span>{" "}
                                    <span>Red</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/terracotta-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch15"></span>{" "}
                                    <span>Terracotta</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/multi-colour-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch16"></span>{" "}
                                    <span>Multi-colour</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                  <li>
                    <span
                      className={
                        isClassToggled[7]
                          ? "menu-arrow sublink-open"
                          : "menu-arrow"
                      }
                      onClick={() => toggleClassInMenu(7)}
                    >
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        if (isClassToggled[7]) {
                          window.location =
                            "https://www.orientbell.com/tiles/wooden-tiles";
                        }
                        toggleClassInMenu2(7);
                      }}
                      href="https://www.orientbell.com/tiles/wooden-tiles"
                    >
                      Wooden Tiles
                    </a>
                    {isClassToggled[7] && (
                      <div className="obl-submenu-row">
                        <div className="container">
                          <div className="obl-submenu-nav flex-class">
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[70]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(70)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Wood Tiles
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/floor-tiles?tiles=wooden-tiles">
                                    Wooden Floor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/wall-tiles?tiles=wooden-tiles">
                                    Wooden Wall Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/wooden-tiles?tiles=wooden-tiles">
                                    Wooden Plank Tiles
                                  </a>
                                </li>
                              </ul>
                              <div className="">
                                <div
                                  className={
                                    isTitleHeadToggled[71]
                                      ? "titleHead submenu-open"
                                      : "titleHead"
                                  }
                                  onClick={() => toggleTitleHead(71)}
                                >
                                  <a
                                    href="#"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Wood Tiles by Spaces
                                  </a>
                                </div>
                                <ul>
                                  <li className="walltile-sub">
                                    <a href="#">Bedroom Wooden Tiles</a>
                                  </li>
                                  <li className="walltile-sub">
                                    <a href="#">Wooden Elevation Tiles</a>
                                  </li>
                                  <li className="walltile-sub">
                                    <a href="#">Wood Bathroom Tiles</a>
                                  </li>
                                  <li className="walltile-sub">
                                    <a href="#">Wood Kitchen Tiles</a>
                                  </li>
                                  <li className="walltile-sub">
                                    <a href="#">Wooden Balcony Tiles</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[72]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(72)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Wooden Tiles by Size
                                </a>
                              </div>
                              <ul>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/300x600-tiles?tiles=wooden-tiles">
                                    300x600 mm Wooden Tiles
                                  </a>
                                </li>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/2x2-tiles?tiles=wooden-tiles">
                                    600x600 mm Wooden Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/1x1-tiles?tiles=wooden-tiles">
                                    Small Wooden Tiles
                                  </a>
                                </li>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/1x1-tiles?tiles=wooden-tiles">
                                    300x300 mm Wooden Tiles
                                  </a>
                                </li>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/295x295-tiles?tiles=wooden-tiles">
                                    295x295 mm Wooden Tiles
                                  </a>
                                </li>{" "}
                                <li>
                                  <a href="https://www.orientbell.com/tiles/wooden-tiles?tiles=wooden-tiles">
                                    Plank Wooden Tiles
                                  </a>
                                </li>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/145x600-tiles?tiles=wooden-tiles">
                                    145x600 mm Wooden Tiles
                                  </a>
                                </li>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/195x1200-tiles?tiles=wooden-tiles">
                                    195x1200 mm Wooden Tiles
                                  </a>
                                </li>{" "}
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/200x1200-tiles?tiles=wooden-tiles">
                                    200x1200 mm Wooden Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[73]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(73)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Popular Wooden Tile Categories
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/ceramic-tiles?tiles=wooden-tiles">
                                    Ceramic Wooden Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles?tiles=wooden-tiles">
                                    Vitrified Wooden Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/gvt-tiles?tiles=wooden-tiles">
                                    Glazed Vitrified Wooden Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    Double Glazed Vitrified Wooden Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/pgvt-tiles?tiles=wooden-tiles">
                                    Polished Glazed Vitrified Wooden Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="#">
                                    Polished Vitrified Wooden Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/full-body-vitrified-tiles?tile_type=120">
                                    Full Body Vitrified Wooden Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/designer-tiles?tiles=wooden-tiles">
                                    Designer Wooden Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[74]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(74)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Popular Wooden Finishes
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/glossy-tiles?tiles=wooden-tiles">
                                    Glossy Finish
                                  </a>
                                </li>
                                <li>
                                  <a href="#">Super Glossy Finish</a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/matte-finish-tiles?tiles=wooden-tiles">
                                    Matte Finish
                                  </a>
                                </li>
                                {/* <li>
                                <a href="#">Satin Matte Finish</a>
                              </li> */}
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[75]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(75)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Recommended Wooden Tiles
                                </a>
                              </div>
                              <ul className="titlepattern">
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern1 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh-unisquare-leaf-hl.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh-unisquare-leaf-hl.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh-unisquare-leaf-hl.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/odh-unisquare-leaf-hl-015005765041692011m"
                                          className="murllink"
                                        >
                                          ODH Unisquare Leaf HL
                                        </a>{" "}
                                        <span>300x600 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern2 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odp-aster-wood-brown.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odp-aster-wood-brown.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odp-aster-wood-brown.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/gft-odp-aster-wood-ft-brown"
                                          className="murllink"
                                        >
                                          GFT ODP Aster Wood Brown
                                        </a>{" "}
                                        <span>600x600 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern3 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh_leathra_hl.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh_leathra_hl.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh_leathra_hl.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/odh-leathra-hl-hl-015005753271343011m"
                                          className="murllink"
                                        >
                                          ODH Leathra HL
                                        </a>{" "}
                                        <span>300x600 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <div
                                className={
                                  isTitleHeadToggled[76]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(76)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_design=354&amp;popularity=101&amp;mt=w">
                                  Popular Tiles
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[77]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(77)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_design=354&amp;mt=w&amp;trending=1">
                                  #Trending Tiles
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[78]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(78)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_design=354&amp;mt=w&amp;product_list_dir=asc">
                                  Latest Collection
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[79]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(79)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_design=354&amp;mt=w">
                                  View all Tiles
                                </a>
                              </div>
                            </div>
                            <div className="tilecolor">
                              <div
                                className={
                                  isTitleHeadToggled[80]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(80)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Popular Tile Colour
                                </a>
                              </div>
                              <ul className="colorList flex-class">
                                <li>
                                  <a href="https://www.orientbell.com/tiles/white-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch1"></span>{" "}
                                    <span>White</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/black-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch2"></span>{" "}
                                    <span>Black</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/brown-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch4"></span>{" "}
                                    <span>Brown</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/beige-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch5"></span>{" "}
                                    <span>Beige</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/ivory-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch6"></span>{" "}
                                    <span>Ivory</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/cream-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch7"></span>{" "}
                                    <span>Cream</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/yellow-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch8"></span>{" "}
                                    <span>Yellow</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/blue-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch9"></span>{" "}
                                    <span>Blue</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/green-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch10"></span>{" "}
                                    <span>Green</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/grey-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch11"></span>{" "}
                                    <span>Grey</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/pink-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch12"></span>{" "}
                                    <span>Pink</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/red-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch14"></span>{" "}
                                    <span>Red</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/terracotta-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch15"></span>{" "}
                                    <span>Terracotta</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/multi-colour-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch16"></span>{" "}
                                    <span>Multi-colour</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                  <li>
                    <span
                      className={
                        isClassToggled[8]
                          ? "menu-arrow sublink-open"
                          : "menu-arrow"
                      }
                      onClick={() => toggleClassInMenu(8)}
                    >
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        if (isClassToggled[8]) {
                          window.location =
                            "https://www.orientbell.com/tiles/vitrified-tiles";
                        }
                        toggleClassInMenu2(8);
                      }}
                      href="https://www.orientbell.com/tiles/vitrified-tiles"
                    >
                      Vitrified Tiles
                    </a>
                    {isClassToggled[8] && (
                      <div className="obl-submenu-row">
                        <div className="container">
                          <div className="obl-submenu-nav flex-class">
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[81]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(81)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Vitrified Tiles
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/floor-tiles?tile_type=120">
                                    Vitrified Floor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/wall-tiles?tile_type=120">
                                    Vitrified Wall Tiles
                                  </a>
                                </li>
                              </ul>
                              <div className="">
                                <div
                                  className={
                                    isTitleHeadToggled[82]
                                      ? "titleHead submenu-open"
                                      : "titleHead"
                                  }
                                  onClick={() => toggleTitleHead(82)}
                                >
                                  <a
                                    href="#"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Vitrified Tiles Spaces
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <a href="https://www.orientbell.com/tiles/living-room-tiles?tile_type=120">
                                      Living Room Vitrified Tiles
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://www.orientbell.com/tiles/vitrified-tiles?tile_area=103">
                                      Bathroom Vitrified Tiles
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://www.orientbell.com/tiles/parking-tiles?tile_type=120">
                                      Parking Vitrified Tiles
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://www.orientbell.com/tiles/kitchen-tiles?tile_type=120">
                                      Kitchen Vitrified Tiles
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[83]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(83)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Vitrified Tiles by Size
                                </a>
                              </div>
                              <ul>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles?tile_size=497">
                                    1200X1800 mm Vitrified Tiles
                                  </a>
                                </li>
                                {/* <li className="walltile-sub">
                                <a href="https://www.orientbell.com/tiles/1200x1200-tiles?tiles=vitrified-tiles">
                                  1200X1200 mm Vitrified Tiles
                                </a>
                              </li> */}
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles?tile_size=260">
                                    800X1600 mm Vitrified Tiles
                                  </a>
                                </li>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles?tile_size=263">
                                    800X800 mm Vitrified Tiles
                                  </a>
                                </li>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles?tile_size=262">
                                    600X1200 mm Vitrified Tiles
                                  </a>
                                </li>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles?tile_size=264">
                                    600x600 mm Vitrified Tiles
                                  </a>
                                </li>{" "}
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles?tile_size=266">
                                    300 X 600 mm Vitrified Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles?tile_design=355&tile_category=223">
                                    Plank Floor Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[84]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(84)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Vitrified Tile Categories
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles?tile_type=140">
                                    Anti-skid Vitrified Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles?tile_type=127">
                                    Double Charge Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/gvt-tiles">
                                    Glazed Vitrified Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/pgvt-tiles">
                                    Polished Glazed Vitrified Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/full-body-vitrified-tiles">
                                    Full Body Vitrified Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/polished-vitrified-tiles">
                                    Polished Vitrified Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/vitrified-tiles/dgvt-tiles">
                                    Digital Glazed Vitrified Tiles
                                  </a>
                                </li>{" "}
                                {/* <li>
                                <a href="https://www.orientbell.com/tiles/printed-double-charge-tiles?tile_type=120">
                                  Printed Double Charge Vitrified Tiles
                                </a>
                              </li> */}
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[85]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(85)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Vitrified Tile Designs
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/marble-tiles?tile_type=120">
                                    Marble Vitrified Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/wooden-tiles?tile_type=120">
                                    Wooden Vitrified Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/plain-tiles?tile_type=120">
                                    Plain Vitrified Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/onyx-tiles?tile_type=120">
                                    Onyx Vitrified Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/statuario-tiles?tile_type=120">
                                    Statutario Marble Vitrified Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/stone-tiles?tile_type=120">
                                    Stone Vitrified Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/granite-tiles?tile_type=120">
                                    Travertine Marble Vitrified Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/book-match-tiles?tile_type=120">
                                    Bookmatch Marble Vitrified Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[86]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(86)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Recommended Vitrified Tiles for you
                                </a>
                              </div>
                              <ul className="titlepattern">
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern1 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/canto-almond.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/canto-almond.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/canto-almond.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/canto-almond-marble-double-charge-vitrified-floor-tiles"
                                          className="murllink"
                                        >
                                          Canto Almond
                                        </a>{" "}
                                        <span>600x600 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern2 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/dgvt-poplar-beige.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/dgvt-poplar-beige.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/dgvt-poplar-beige.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/dgvt-poplar-beige-025617256670218031m"
                                          className="murllink"
                                        >
                                          DGVT Poplar Beige
                                        </a>{" "}
                                        <span>145x600 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern3 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/star-sandune.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/star-sandune.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/star-sandune.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/star-sandune-marble-double-charge-vitrified-floor-tiles"
                                          className="murllink"
                                        >
                                          Star Sandune
                                        </a>{" "}
                                        <span>600x600 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <div
                                className={
                                  isTitleHeadToggled[87]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(87)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_type=120&amp;popularity=101&amp;mt=v">
                                  Popular Vitrified Tiles
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[88]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(88)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_type=120&amp;mt=v&amp;product_list_dir=asc">
                                  Latest Collection
                                </a>
                              </div>
                            </div>
                            <div className="tilecolor">
                              <div
                                className={
                                  isTitleHeadToggled[89]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(89)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Popular Tile Colour
                                </a>
                              </div>
                              <ul className="colorList flex-class">
                                <li>
                                  <a href="https://www.orientbell.com/tiles/white-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch1"></span>{" "}
                                    <span>White</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/black-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch2"></span>{" "}
                                    <span>Black</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/brown-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch4"></span>{" "}
                                    <span>Brown</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/beige-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch5"></span>{" "}
                                    <span>Beige</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/ivory-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch6"></span>{" "}
                                    <span>Ivory</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/cream-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch7"></span>{" "}
                                    <span>Cream</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/yellow-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch8"></span>{" "}
                                    <span>Yellow</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/blue-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch9"></span>{" "}
                                    <span>Blue</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/green-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch10"></span>{" "}
                                    <span>Green</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/grey-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch11"></span>{" "}
                                    <span>Grey</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/pink-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch12"></span>{" "}
                                    <span>Pink</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/red-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch14"></span>{" "}
                                    <span>Red</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/terracotta-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch15"></span>{" "}
                                    <span>Terracotta</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/multi-colour-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch16"></span>{" "}
                                    <span>Multi-colour</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                  <li>
                    <span
                      className={
                        isClassToggled[9]
                          ? "menu-arrow sublink-open"
                          : "menu-arrow"
                      }
                      onClick={() => toggleClassInMenu(9)}
                    >
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        if (isClassToggled[9]) {
                          window.location =
                            "https://www.orientbell.com/tiles/ceramic-tiles";
                        }
                        toggleClassInMenu2(9);
                      }}
                      href="https://www.orientbell.com/tiles/ceramic-tiles"
                    >
                      Ceramic Tiles
                    </a>
                    {isClassToggled[9] && (
                      <div className="obl-submenu-row">
                        <div className="container">
                          <div className="obl-submenu-nav flex-class">
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[90]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(90)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Ceramic Tiles
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/floor-tiles?tile_type=118">
                                    Ceramic Floor Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/wall-tiles?tile_type=118">
                                    Ceramic Wall Tiles
                                  </a>
                                </li>
                              </ul>
                              <div className="">
                                <div
                                  className={
                                    isTitleHeadToggled[91]
                                      ? "titleHead submenu-open"
                                      : "titleHead"
                                  }
                                  onClick={() => toggleTitleHead(91)}
                                >
                                  <a
                                    href="#"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Ceramic Tiles by Finish
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <a href="https://www.orientbell.com/tiles/matte-finish-tiles?tile_type=118">
                                      Matte Finish Ceramic Tiles
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://www.orientbell.com/tiles/glossy-tiles?tile_type=118">
                                      Glossy Finish Ceramic Tiles
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[92]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(92)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Ceramic Tiles by Sizes
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/2x2-tiles?tile_type=118">
                                    Regular Ceramic Tiles
                                  </a>
                                </li>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/2x2-tiles?tile_type=118">
                                    600x600 mm Ceramic Tiles
                                  </a>
                                </li>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/300x450-tiles?tile_type=118">
                                    300x450 mm Ceramic Tiles
                                  </a>
                                </li>{" "}
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/300x600-tiles?tile_type=118">
                                    300x600 mm Ceramic Tiles
                                  </a>
                                </li>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/tiles/395x395-tiles?tile_type=118">
                                    395x395 mm Ceramic Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/1x1-tiles?tile_type=118">
                                    Small Ceramic Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[93]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(93)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Ceramic Tile by Spaces
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/bathroom-tiles?tile_type=118">
                                    Bathroom Ceramic Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/kitchen-tiles?tile_type=118">
                                    Kitchen Ceramic Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/living-room-tiles?tile_type=118">
                                    Living Room Ceramic Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/bedroom-tiles?tile_type=118">
                                    Bedroom Ceramic Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/parking-tiles?tile_type=118">
                                    Outdoor/Parking Ceramic Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/high-traffic-tiles?tile_type=118">
                                    High-Traffic Ceramic Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/commercial-tiles?tile_type=118">
                                    Commercial/Office Area Ceramic Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/elevation-tiles?tile_type=118">
                                    Elevation Ceramic Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[94]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(94)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Ceramic Tile Designs
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/wooden-tiles?tile_type=118">
                                    Wooden Ceramic Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/marble-tiles?tile_type=118">
                                    Marble Ceramic Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/plain-tiles?tile_type=118">
                                    Plain Ceramic Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/mosaic-tiles?tile_type=118">
                                    Mosaic Ceramic Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/cement-tiles?tile_type=118">
                                    Cement Ceramic Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/geometric-tiles?tile_type=118">
                                    Geometric Ceramic Tiles
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/flower-tiles?tile_type=118">
                                    Floral Ceramic Tiles
                                  </a>
                                </li>
                              </ul>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[95]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(95)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Recommended Ceramic Tiles for you
                                </a>
                              </div>
                              <ul className="titlepattern">
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern1 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh-unisquare-leaf-hl.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh-unisquare-leaf-hl.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh-unisquare-leaf-hl.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/odh-unisquare-leaf-hl-015005765041692011m"
                                          className="murllink"
                                        >
                                          ODH Unisquare Leaf HL
                                        </a>{" "}
                                        <span>300x600 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern2 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odp-aster-wood-brown.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odp-aster-wood-brown.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odp-aster-wood-brown.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/odp-aster-wood-ft-brown-024606662170249361d"
                                          className="murllink"
                                        >
                                          GFT ODP Aster Wood Brown
                                        </a>{" "}
                                        <span>600x600 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <li>
                                  <div className="titlepattern">
                                    <div className="pattern3 pattern">
                                      <img
                                        loading="lazy"
                                        srcSet="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh_leathra_hl.jpg?imgeng=w_10 480w, https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh_leathra_hl.jpg 800w"
                                        sizes="(max-width: 600px) 480px, 800px"
                                        src="https://88nbxydt.cdn.imgeng.in/media/recommendedproducts/odh_leathra_hl.jpg"
                                      />
                                      <div className="patternName">
                                        <a
                                          href="https://www.orientbell.com/odh-leathra-hl-hl-015005753271343011m"
                                          className="murllink"
                                        >
                                          ODH Leathra HL
                                        </a>{" "}
                                        <span>300x600 mm</span>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              </ul>
                              <div
                                className={
                                  isTitleHeadToggled[96]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(96)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_type=118&amp;popularity=101&amp;mt=c">
                                  Popular Ceramic Tiles
                                </a>
                              </div>
                              <div
                                className={
                                  isTitleHeadToggled[97]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(97)}
                              >
                                <a href="https://www.orientbell.com/tiles?tile_type=118&amp;mt=c&amp;product_list_order=latest&amp;product_list_dir=asc">
                                  Latest Ceramic Tiles Collection
                                </a>
                              </div>
                            </div>
                            <div className="tilecolor">
                              <div
                                className={
                                  isTitleHeadToggled[98]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(98)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Popular Tile Colour
                                </a>
                              </div>
                              <ul className="colorList flex-class">
                                <li>
                                  <a href="https://www.orientbell.com/tiles/white-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch1"></span>{" "}
                                    <span>White</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/black-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch2"></span>{" "}
                                    <span>Black</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/brown-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch4"></span>{" "}
                                    <span>Brown</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/beige-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch5"></span>{" "}
                                    <span>Beige</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/ivory-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch6"></span>{" "}
                                    <span>Ivory</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/cream-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch7"></span>{" "}
                                    <span>Cream</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/yellow-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch8"></span>{" "}
                                    <span>Yellow</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/blue-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch9"></span>{" "}
                                    <span>Blue</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/green-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch10"></span>{" "}
                                    <span>Green</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/grey-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch11"></span>{" "}
                                    <span>Grey</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/pink-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch12"></span>{" "}
                                    <span>Pink</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/red-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch14"></span>{" "}
                                    <span>Red</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/terracotta-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch15"></span>{" "}
                                    <span>Terracotta</span>
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/multi-colour-tiles?tiles=floor-tiles">
                                    <span className="swatch swatch16"></span>{" "}
                                    <span>Multi-colour</span>
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>

                  <li>
                    <span
                      className={
                        isClassToggled[12]
                          ? "menu-arrow sublink-open"
                          : "menu-arrow"
                      }
                      onClick={() => toggleClassInMenu(12)}
                    >
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        if (isClassToggled[12]) {
                          window.location =
                            "https://www.orientbell.com/design-ideas";
                        }
                        toggleClassInMenu2(12);
                      }}
                      href="https://www.orientbell.com/design-ideas/"
                    >
                      Design Ideas
                    </a>
                    {isClassToggled[12] && (
                      <div className="obl-submenu-row">
                        <div className="container">
                          <div className="obl-submenu-nav flex-class">
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[110]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(110)}
                              >
                                <a href="/design-ideas/bathroom-designs">
                                  Bathroom Designs
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="/design-ideas/bathroom-designs/master-bathroom">
                                    Master Bathroom Designs
                                  </a>
                                </li>
                                <li>
                                  <a href="/design-ideas/bathroom-designs/small-bathroom">
                                    Small Bathroom Designs
                                  </a>
                                </li>
                                <li>
                                  <a href="/design-ideas/bathroom-designs/marble-bathroom">
                                    Marble Bathroom Designs
                                  </a>
                                </li>
                                <li>
                                  <a href="/design-ideas/bathroom-designs/mosaic-bathroom">
                                    Mosaic Bathroom Designs
                                  </a>
                                </li>
                                <li>
                                  <a href="/design-ideas/bathroom-designs/beige-bathroom">
                                    Beige Bathroom Designs
                                  </a>
                                </li>
                                <li>
                                  <a href="/design-ideas/bathroom-designs/blue-bathroom">
                                    Blue Bathroom Designs
                                  </a>
                                </li>
                                <li>
                                  <a href="/design-ideas/bathroom-designs/grey-bathroom">
                                    Grey Bathroom Designs
                                  </a>
                                </li>
                                <li>
                                  <a href="/design-ideas/bathroom-designs/white-bathroom">
                                    White Bathroom Designs
                                  </a>
                                </li>

                                <li>
                                  <a href="/design-ideas/bathroom-designs/black-and-white-bathroom">
                                    Black & White Bathroom Designs
                                  </a>
                                </li>

                                <li>
                                  <a href="/design-ideas/bathroom-designs/pink-bathroom">
                                    Pink Bathroom
                                  </a>
                                </li>
                                <li>
                                  <a href="/design-ideas/bathroom-designs/wooden-bathroom">
                                    Wooden Bathroom
                                  </a>
                                </li>
                                <li>
                                  <a href="/design-ideas/bathroom-designs/green-bathroom">
                                    Green Bathroom
                                  </a>
                                </li>
                              </ul>
                            </div>

                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[108]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(108)}
                              >
                                <a href="/design-ideas/kitchen-designs">
                                  Kitchen Designs
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="/design-ideas/kitchen-designs/simple-kitchen">
                                    Simple Kitchen Designs
                                  </a>
                                </li>
                                <li>
                                  <a href="/design-ideas/kitchen-designs/small-kitchen">
                                    Small Kitchen Designs
                                  </a>
                                </li>
                                <li>
                                  <a href="/design-ideas/kitchen-designs/white-kitchen">
                                    White Kitchen Designs
                                  </a>
                                </li>
                                <li>
                                  <a href="/design-ideas/kitchen-designs/beige-and-brown-kitchen">
                                    Beige & Brown Kitchen Designs
                                  </a>
                                </li>
                                <li>
                                  <a href="/design-ideas/kitchen-designs/l-shape-kitchen">
                                    L-Shaped Kitchen Designs
                                  </a>
                                </li>
                                <li>
                                  <a href="/design-ideas/kitchen-designs/kitchen-backsplash">
                                    Kitchen Backsplash Designs
                                  </a>
                                </li>
                                <li>
                                  <a href="/design-ideas/kitchen-designs/parallel-kitchen">
                                    Parallel Kitchen Designs
                                  </a>
                                </li>
                                <li>
                                  <a href="/design-ideas/kitchen-designs/open-kitchen">
                                    Open Kitchen Designs
                                  </a>
                                </li>
                                <li>
                                  <a href="/design-ideas/kitchen-designs/peninsula-kitchen">
                                    Peninsula Kitchen Designs
                                  </a>
                                </li>
                                <li>
                                  <a href="/design-ideas/kitchen-designs/blue-kitchen">
                                    Blue Kitchen Designs
                                  </a>
                                </li>
                                <li>
                                  <a href="/design-ideas/kitchen-designs/i-shape-kitchen">
                                    I-Shaped Kitchen Designs
                                  </a>
                                </li>
                                <li>
                                  <a href="/design-ideas/kitchen-designs/island-kitchen">
                                    Island Kitchen Designs
                                  </a>
                                </li>
                              </ul>
                            </div>

                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[111]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(111)}
                              >
                                <a href="https://www.orientbell.com/design-ideas/living-room-designs">
                                  Living Room Designs
                                </a>
                              </div>

                              <div className="titleBox">
                                <div
                                  className={
                                    isTitleHeadToggled[112]
                                      ? "titleHead submenu-open"
                                      : "titleHead"
                                  }
                                  onClick={() => toggleTitleHead(112)}
                                >
                                  <a href="https://www.orientbell.com/design-ideas/bedroom-designs">
                                    Bedroom Designs
                                  </a>
                                </div>
                              </div>

                              <div className="titleBox">
                                <div
                                  className={
                                    isTitleHeadToggled[113]
                                      ? "titleHead submenu-open"
                                      : "titleHead"
                                  }
                                  onClick={() => toggleTitleHead(113)}
                                >
                                  <a href="https://www.orientbell.com/design-ideas/pooja-room-designs">
                                    Pooja Room Designs
                                  </a>
                                </div>
                              </div>

                              <div className="titleBox">
                                <div
                                  className={
                                    isTitleHeadToggled[114]
                                      ? "titleHead submenu-open"
                                      : "titleHead"
                                  }
                                  onClick={() => toggleTitleHead(114)}
                                >
                                  <a href="https://www.orientbell.com/design-ideas/wash-basin-designs">
                                    Wash Basin Designs
                                  </a>
                                </div>
                              </div>
                              <div className="titleBox">
                                <div
                                  className={
                                    isTitleHeadToggled[115]
                                      ? "titleHead submenu-open"
                                      : "titleHead"
                                  }
                                  onClick={() => toggleTitleHead(115)}
                                >
                                  <a href="https://www.orientbell.com/design-ideas/countertop-designs">
                                    Countertop Designs
                                  </a>
                                </div>
                              </div>

                              <div className="titleBox">
                                <div
                                  className={
                                    isTitleHeadToggled[116]
                                      ? "titleHead submenu-open"
                                      : "titleHead"
                                  }
                                  onClick={() => toggleTitleHead(116)}
                                >
                                  <a href="https://www.orientbell.com/design-ideas/front-elevation-designs">
                                    Front Elevation Designs
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>

                  <li className="catalog-mobile-view-menu">
                    <a
                      href={
                        router.asPath.includes("/export-download-catalog") ||
                        router.asPath.includes("/exports-project")
                          ? "https://www.orientbell.com/download-export-catalogue"
                          : "https://www.orientbell.com/download-catalogue"
                      }
                    >
                      {" "}
                      Download Catalogue
                    </a>{" "}
                  </li>

                  <li className="blog_menu_new">
                    <span
                      className={
                        isClassToggled[10]
                          ? "menu-arrow sublink-open"
                          : "menu-arrow"
                      }
                      onClick={() => toggleClassInMenu(10)}
                    >
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                    <a href="https://www.orientbell.com/blog">Blog</a>

                    <div className="obl-submenu-row dr-new-one">
                      <div className="container">
                        <div className="obl-submenu-nav flex-class">
                          <div className="titleBox">
                            <div
                              className={
                                isTitleHeadToggled[99]
                                  ? "titleHead submenu-open"
                                  : "titleHead"
                              }
                              onClick={() => toggleTitleHead(99)}
                            >
                              <a href="https://www.orientbell.com/blog/web-story">
                                Web Stories
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </li>
                  <li>
                    <span
                      className={
                        isClassToggled[11]
                          ? "menu-arrow sublink-open"
                          : "menu-arrow"
                      }
                      onClick={() => toggleClassInMenu(11)}
                    >
                      <i className="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                    <a
                      onClick={(e) => {
                        e.preventDefault();
                        toggleClassInMenu2(11);
                      }}
                      href="#"
                    >
                      More
                    </a>
                    {isClassToggled[11] && (
                      <div className="obl-submenu-row">
                        <div className="container">
                          <div className="obl-submenu-nav flex-class">
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[100]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(100)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  About OBL
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/about-us">
                                    Company Profile
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/core_values">
                                    Core Values
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/our-milestones">
                                    Milestones
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/about-us-awards-accolades">
                                    Awards &amp; Accolades
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/executive-managers">
                                    Leadership Team
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/board-of-director">
                                    Board of Directors
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/innovation">
                                    Innovation
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/manufacturing-capabilities">
                                    Manufacturing Capabilities
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/csr">
                                    CSR
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/product-quality">
                                    Product Quality
                                  </a>
                                </li>
                              </ul>

                              <div className="titleBox">
                                <div
                                  className={
                                    isTitleHeadToggled[101]
                                      ? "titleHead submenu-open"
                                      : "titleHead"
                                  }
                                  onClick={() => toggleTitleHead(101)}
                                >
                                  <a
                                    href="#"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Contact us
                                  </a>
                                </div>

                                <ul>
                                  <li>
                                    <a href="https://www.orientbell.com/faq">
                                      FAQs
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://www.orientbell.com/contact-us">
                                      Corporate Office
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://www.orientbell.com/signature-company-showrooms">
                                      Signature Company Showrooms
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href="https://stores.orientbell.com/"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      Orientbell Exclusive Boutiques
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://www.orientbell.com/store-locator">
                                      Store Locator
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[102]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(102)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Large Projects
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/projects-at-orientbell">
                                    Project @ Orientbell
                                  </a>
                                </li>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/residential-projects">
                                    Residential Project
                                  </a>
                                </li>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/commercial-projects">
                                    Commercial Project
                                  </a>
                                </li>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/government-projects">
                                    Government Project
                                  </a>
                                </li>
                                <li className="walltile-sub">
                                  <a href="https://www.orientbell.com/industrial-projects">
                                    Industrial Project
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/builders-contractors">
                                    Builders Contractors
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/exports-project">
                                    Exports
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/our-capabilities">
                                    Our Capabilities
                                  </a>
                                </li>
                              </ul>
                              <div className="titleBox">
                                <div
                                  className={
                                    isTitleHeadToggled[107]
                                      ? "titleHead submenu-open"
                                      : "titleHead"
                                  }
                                  onClick={() => toggleTitleHead(107)}
                                >
                                  <a
                                    href="#"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Tools
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <a
                                      href={`${config.HEADLESS_BASE_URL}/tile-calculator`}
                                    >
                                      Tile Calculator
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href={`${config.HEADLESS_BASE_URL}/area-conversion-calculator`}
                                    >
                                      Area Calculator
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href={`${config.HEADLESS_BASE_URL}/paint-cost-calculator`}
                                    >
                                      Paint Calculator
                                    </a>
                                  </li>
                                  <li>
                                    <a
                                      href={`${config.HEADLESS_BASE_URL}/construction-cost-calculator`}
                                    >
                                      Cost Estimator
                                    </a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[103]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(103)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Investor Relations
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/investor#disclosuresUnderRegulation46OfSEBI(LODR)Regulations">
                                    Disclosures under Regulation 46 of SEBI
                                    (LODR) Regulations
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/investor#otherStatutoryDisclosures">
                                    Other Statutory Disclosures
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/investor#investorInformation">
                                    Investor Information
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/investor#Dividend">
                                    Dividend
                                  </a>
                                </li>
                              </ul>
                              <div className="titleBox">
                                <div
                                  className={
                                    isTitleHeadToggled[104]
                                      ? "titleHead submenu-open"
                                      : "titleHead"
                                  }
                                  onClick={() => toggleTitleHead(104)}
                                >
                                  <a
                                    href="#"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Career
                                  </a>
                                </div>
                                <ul>
                                  <li>
                                    <a href="https://www.orientbell.com/career">
                                      Get to know us
                                    </a>
                                  </li>
                                  <li>
                                    <a href="https://www.orientbell.com/career">
                                      Life@OBL
                                    </a>
                                  </li>
                                  {/* <li>
                                <a href="https://www.orientbell.com/career#opening">
                                  Current Openings
                                </a>
                              </li> */}
                                </ul>
                                <div className="titleBox">
                                  <div
                                    className={
                                      isTitleHeadToggled[105]
                                        ? "titleHead submenu-open"
                                        : "titleHead"
                                    }
                                    onClick={() => toggleTitleHead(105)}
                                  >
                                    <a
                                      href="#"
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      Media
                                    </a>
                                  </div>
                                  <ul>
                                    <li>
                                      <a href="https://www.orientbell.com/press-release">
                                        Press Release
                                      </a>
                                    </li>
                                    <li>
                                      <a href="https://www.orientbell.com/event">
                                        Events
                                      </a>
                                    </li>
                                    <li>
                                      <a href="https://www.orientbell.com/latest-news">
                                        Latest News
                                      </a>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>

                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[109]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(109)}
                              >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                  Locations
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/delhi">
                                    Delhi
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/mumbai">
                                    Mumbai
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/bangalore">
                                    Bengaluru
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/darjeeling">
                                    Darjeeling
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/alipurduar">
                                    Alipurduar
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/jangipur">
                                    Jangipur
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/raiganj">
                                    Raiganj
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/hyderabad">
                                    Hyderabad
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/chennai">
                                    Chennai
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/kolkata">
                                    Kolkata
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/lucknow">
                                    Lucknow
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/pune">
                                    Pune
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/bhopal">
                                    Bhopal
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/jabalpur">
                                    Jabalpur
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/indore">
                                    Indore
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/nagpur">
                                    Nagpur
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/coimbatore">
                                    Coimbatore
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/patna">
                                    Patna
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/ernakulam">
                                    Ernakulam
                                  </a>
                                </li>

                                <li>
                                  <a href=" https://www.orientbell.com/tiles/narnaul">
                                    Narnaul
                                  </a>
                                </li>
                                <li>
                                  <a href=" https://www.orientbell.com/tiles/mahendragarh">
                                    Mahendragarh
                                  </a>
                                </li>
                                <li>
                                  <a href=" https://www.orientbell.com/tiles/sohna">
                                    Sohna
                                  </a>
                                </li>
                                <li>
                                  <a href=" https://www.orientbell.com/tiles/nuh">
                                    Nuh
                                  </a>
                                </li>
                                <li>
                                  <a href=" https://www.orientbell.com/tiles/panchkula">
                                    Panchkula
                                  </a>
                                </li>
                                <li>
                                  <a href=" https://www.orientbell.com/tiles/noida">
                                    Noida
                                  </a>
                                </li>
                                <li>
                                  <a href=" https://www.orientbell.com/tiles/gurugram">
                                    Gurugram
                                  </a>
                                </li>
                                <li>
                                  <a href=" https://www.orientbell.com/tiles/pataudi">
                                    Pataudi
                                  </a>
                                </li>
                                <li>
                                  <a href=" https://www.orientbell.com/tiles/bhiwadi">
                                    Bhiwadi
                                  </a>
                                </li>
                                <li>
                                  <a href=" https://www.orientbell.com/tiles/manesar">
                                    Manesar
                                  </a>
                                </li>
                                <li>
                                  <a href=" https://www.orientbell.com/tiles/dharuhera">
                                    Dharuhera
                                  </a>
                                </li>
                                <li>
                                  <a href=" https://www.orientbell.com/tiles/farukhnagar">
                                    Farukhnagar
                                  </a>
                                </li>
                              </ul>
                            </div>

                            <div className="titleBox">
                              <div
                                className={
                                  isTitleHeadToggled[106]
                                    ? "titleHead submenu-open"
                                    : "titleHead"
                                }
                                onClick={() => toggleTitleHead(106)}
                              >
                                <a href="https://www.orientbell.com/tiles/tile-collection/sparkle">
                                  Tiles Collection
                                </a>
                              </div>
                              <ul>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/sparkle">
                                    Sparkle Tiles Collection
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/estilo">
                                    Estilo Tiles Collection
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/ft-autumn2020">
                                    FT Autumn 2020 Tiles Collection
                                  </a>
                                </li>
                                {/* <li>
                                <a href="https://www.orientbell.com/tiles/tile-collection/zenith">
                                  Zenith Tiles Collection
                                </a>
                              </li> */}
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/sahara">
                                    Sahara Tiles Collection
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/rhino-series-pavers-tiles">
                                    Rhino Tiles Collection
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/duazzle">
                                    Duazzle Tiles Collection
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/inspire">
                                    Inspire Tiles Collection
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/hd-p-elevation">
                                    HD-P Elevation Tiles Collection
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/inspire-planks">
                                    Inspire Planks Tiles Collection
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/inspire3-0">
                                    Inspire 3.0 Collection
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/Granalt">
                                    Granalt Collection
                                  </a>
                                </li>
                                {/* <li>
                                <a href="https://www.orientbell.com/tiles/paris-double-charge-tiles">
                                  Paris Double Charge Tiles Collection
                                </a>
                              </li> */}
                                {/* <li>
                                <a href="https://www.orientbell.com/tiles/tile-collection/versalia">
                                  Versalia Vitrified Tiles Collection
                                </a>
                              </li> */}
                                <li>
                                  <a href="https://www.orientbell.com/tiles/inspire-800-1600-mm">
                                    Inspire – 800x1600
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/inspire-art-collection">
                                    Inspire Art Collection
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/timeless-2-0">
                                    Timeless 2.0
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/duazzle-elevation-series">
                                    Duazzle Elevation
                                  </a>
                                </li>
                                {/* <li>
                                <a href="https://www.orientbell.com/tiles/marvel">
                                  Marvel Collection
                                </a>
                              </li> */}
                                <li>
                                  <a href="https://www.orientbell.com/tiles/river-tiles-collection">
                                    River Tiles Collection
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/estilo2-0">
                                    Estilo 2.0
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/ft-autumn-2-0">
                                    FT Autumn 2.0
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/inspire-steps">
                                    Inspire Steps
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/inspire-select">
                                    Inspire Select
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/serenity-collection">
                                    Serenity Collection
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/inspired-dora-gvt">
                                    Inspire - Dora
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/sahara-double-body">
                                    Sahara Double Body
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/inspire-special">
                                    Inspire Special
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/valencica-prime">
                                    Valencica Prime
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/tile-collection/inspire-dora-special">
                                    Inspire Dora Special
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/mandala-collection">
                                    Mandala Art
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/300x300-hsk-pavers">
                                    300X300 HSK Pavers
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com//tiles/craft-cladding-collection">
                                    Craft Cladding Collection
                                  </a>
                                </li>
                                <li>
                                  <a href="https://www.orientbell.com/tiles/touch-feel-gvt-dora">
                                    Touch & Feel GVT Dora
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* {showSearchBox && onSearchRender()} */}
        {Responsive()}
        <Modal
          show={getInTouch}
          className="pincode-popup-bx expert_assistance_modal"
          onHide={() => setGetInTouch(false)}
        >
          <Modal.Dialog>
            <Modal.Header closeButton>
              <Modal.Title>Would you like us to contact you?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form
                onSubmit={(e) => {
                  e.preventDefault();
                  submitGetInTouch();
                }}
              >
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Name*"
                    onChange={(e) => {
                      setTouch({
                        ...touch,
                        name: e.target.value,
                      });
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Mobile Number*"
                    onChange={(e) => {
                      setTouch({
                        ...touch,
                        number: e.target.value,
                      });
                    }}
                  />
                </Form.Group>

                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Label>Pincode</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Pincode"
                    onChange={(e) => {
                      setTouch({
                        ...touch,
                        pincode: e.target.value,
                      });
                    }}
                  />
                </Form.Group>
                {touch.error && (
                  <p className="error text-danger">{touch.error}</p>
                )}

                <Button
                  variant="primary"
                  type="submit"
                  disabled={touch.loading}
                  style={{ width: "100%" }}
                >
                  {touch.loading ? "Submitting" : "Submit"}
                </Button>
              </Form>
            </Modal.Body>
            {/* <Modal.Footer>
              <Button variant="primary" onClick={() => handlePinSave()}>
                Submit
              </Button>
            </Modal.Footer> */}
          </Modal.Dialog>
        </Modal>

        <Modal
          show={langModal}
          className="pincode-popup-bx expert_assistance_modal language_trans_modal"
          onHide={() => {
            setLangModal(false);
          }}
        >
          <div className="main-location-popup">
            <Modal.Dialog>
              <Modal.Header closeButton>
                <label htmlFor="P9LngDdl">Switch language</label>
                <select
                  id="P9LngDdl"
                  className="mobilelanguga"
                  onChange={(e) => {
                    if (e.target.value) {
                      router.push(e.target.value);
                    }
                  }}
                >
                  <option value="">Select Language</option>
                  <option value={`https://orientbell.com/${router.asPath}`}>
                    English
                  </option>
                  <option
                    value={`https://hindi.orientbell.com/${router.asPath}`}
                  >
                    हिंदी
                  </option>
                  <option
                    value={`https://tamil.orientbell.com/${router.asPath}`}
                  >
                    தமிழ்
                  </option>
                </select>
              </Modal.Header>
              <Modal.Body>
                {/* <AddressAutocomplete setLocationPopop={setLocationPopop}/> */}
              </Modal.Body>
              {/* <Modal.Footer>
              <Button variant="primary" onClick={() => handlePinSave()}>
                Submit
              </Button>
            </Modal.Footer> */}
            </Modal.Dialog>
          </div>
        </Modal>

        {/* <Carousel
  additionalTransfrom={0}
  arrows
  autoPlay
  autoPlaySpeed={1000}
  centerMode={false}
  dotListclassName=""
  draggable
  focusOnSelect={false}
  infinite={false}
  itemclassName=""
  keyBoardControl
  minimumTouchDrag={80}
  pauseOnHover
  renderArrowsWhenDisabled={false}
  renderButtonGroupOutside={false}
  renderDotsOutside={false}
  responsive={{
    desktop: {
      breakpoint: {
        max: 3000,
        min: 1024
      },
      items: 3,
      partialVisibilityGutter: 40
    },
    mobile: {
      breakpoint: {
        max: 464,
        min: 0
      },
      items: 1,
      partialVisibilityGutter: 30
    },
    tablet: {
      breakpoint: {
        max: 1024,
        min: 464
      },
      items: 2,
      partialVisibilityGutter: 30
    }
  }}
  rewind
  rewindWithAnimation={false}
  rtl={false}
  shouldResetAutoplay
  showDots={false}
  slidesToSlide={2}
  swipeable
>
<img src ="https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"/>
<img src ="https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"/>
<img src ="https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"/>
<img src ="https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"/>
<img src ="https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"/>
<img src ="https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"/>
<img src ="https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"/>
<img src ="https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"/>
<img src ="https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"/>
<img src ="https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"/>
<img src ="https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"/>
<img src ="https://images.unsplash.com/photo-1549989476-69a92fa57c36?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=800&q=60"/>
</Carousel> */}
      </div>
      {/* <Script
        src="https://code.jquery.com/jquery-2.2.0.min.js"
        type="text/javascript"
      ></Script> */}
      {/* <Script
        src="js/jquery.min.js"
        type="text/javascript"
        charset="utf-8"
      ></Script>
      <Script src="js/slick.js" type="text/javascript" charset="utf-8"></Script>
      <Script
        src="js/bootstrap.min.js"
        type="text/javascript"
        charset="utf-8"
      ></Script>
      <Script
        src="/scripts/script.js"
        onLoad={() => console.log("your script has been loaded")}
      ></Script> */}

      {/* <Script>
        {$(".category-menu").slick({
          infinite: true,
          centerMode: false,
          slidesToShow: 7,
          slidesToScroll: 1,
          autoplay: false,
          centerMode: true,
          autoplaySpeed: 3000,
          arrows: true,
          dots: false,
          responsive: [
            {
              breakpoint: 1199,
              settings: {
                arrows: true,
                centerMode: true,
                slidesToShow: 6,
              },
            },
            {
              breakpoint: 991,
              settings: {
                arrows: true,
                centerMode: true,
                slidesToShow: 5,
              },
            },
            {
              breakpoint: 680,
              settings: {
                arrows: true,
                centerMode: true,
                slidesToShow: 4,
              },
            },
            {
              breakpoint: 420,
              settings: {
                arrows: true,
                centerMode: true,
                slidesToShow: 4,
              },
            },
          ],
        })}
        ;
      </Script> */}
    </>
  );
};

export default Navbar;
